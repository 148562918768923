export const commonInstruments = [
  { id: "ACCORDION", name: "Accordion" },
  { id: "ACOUSTIC_GUITAR", name: "Acoustic Guitar" },
  { id: "BANJO", name: "Banjo" },
  { id: "BASS", name: "Bass" },
  { id: "BASS_GUITAR", name: "Bass Guitar" },
  { id: "CAJON", name: "Cajon" },
  { id: "CELLO", name: "Cello" },
  { id: "CLARINET", name: "Clarinet" },
  { id: "CYMBALS", name: "Cymbals" },
  { id: "DOUBLE_BASS", name: "Double Bass" },
  { id: "DRUMS", name: "Drums" },
  { id: "ELECTRIC_GUITAR", name: "Electric Guitar" },
  { id: "FLUTE", name: "Flute" },
  { id: "GUITAR", name: "Guitar" },
  { id: "HARMONICA", name: "Harmonica" },
  { id: "HARP", name: "Harp" },
  { id: "MANDOLIN", name: "Mandolin" },
  { id: "MARIMBA", name: "Marimba" },
  { id: "PERCUSSION", name: "Percussion" },
  { id: "PIANO", name: "Piano" },
  { id: "SAXOPHONE", name: "Saxophone" },
  { id: "SHAKERS", name: "Shakers" },
  { id: "SYNTHESIZER", name: "Synthesizer" },
  { id: "TAMBOURINE", name: "Tambourine" },
  { id: "TROMBONE", name: "Trombone" },
  { id: "TRUMPET", name: "Trumpet" },
  { id: "UKULELE", name: "Ukulele" },
  { id: "VIOLIN", name: "Violin" },
  { id: "VOCALS", name: "Vocals" },
  { id: "XYLOPHONE", name: "Xylophone" }
];

export const instrumentsNamesInEnglish = [
  "Accordion",
  "Acoustic Guitar",
  "Banjo",
  "Bass",
  "Bass Guitar",
  "Cajon",
  "Cello",
  "Clarinet",
  "Cymbals",
  "Double Bass",
  "Drums",
  "Electric Guitar",
  "Flute",
  "Guitar",
  "Harmonica",
  "Harp",
  "Mandolin",
  "Maracas",
  "Marimba",
  "Percussion",
  "Piano",
  "Saxophone",
  "Shakers",
  "Synthesizer",
  "Tambourine",
  "Trombone",
  "Trumpet",
  "Ukulele",
  "Violin",
  "Vocals",
  "Xylophone"
];

export const commonInstrumentsSpanish = [
  { id: "ACCORDION", name: "Acordeón" },
  { id: "HARMONICA", name: "Armónica" },
  { id: "HARP", name: "Arpa" },
  { id: "BANJO", name: "Banjo" },
  { id: "BASS", name: "Bajo" },
  { id: "BASS_GUITAR", name: "Bajo Eléctrico" },
  { id: "CAJON", name: "Cajón" },
  { id: "CLARINET", name: "Clarinete" },
  { id: "DOUBLE_BASS", name: "Contrabajo" },
  { id: "DRUMS", name: "Batería" },
  { id: "FLUTE", name: "Flauta" },
  { id: "GUITAR", name: "Guitarra" },
  { id: "ACOUSTIC_GUITAR", name: "Guitarra Acústica" },
  { id: "ELECTRIC_GUITAR", name: "Guitarra Eléctrica" },
  { id: "MANDOLIN", name: "Mandolina" },
  { id: "SHAKERS", name: "Maracas" },
  { id: "MARIMBA", name: "Marimba" },
  { id: "CYMBALS", name: "Platillos" },
  { id: "TAMBOURINE", name: "Pandereta" },
  { id: "PERCUSSION", name: "Percusión" },
  { id: "PIANO", name: "Piano" },
  { id: "SAXOPHONE", name: "Saxofón" },
  { id: "SYNTHESIZER", name: "Sintetizador" },
  { id: "TROMBONE", name: "Trombón" },
  { id: "TRUMPET", name: "Trompeta" },
  { id: "UKULELE", name: "Ukelele" },
  { id: "CELLO", name: "Violonchelo" },
  { id: "VIOLIN", name: "Violín" },
  { id: "VOCALS", name: "Voz" },
  { id: "XYLOPHONE", name: "Xilófono" }
];


export const instrumentsNamesInSpanish = [
  "Acordeón",
  "Arpa",
  "Armónica",
  "Bajo",
  "Bajo Eléctrico",
  "Banjo",
  "Batería",
  "Cajón",
  "Clarinete",
  "Contrabajo",
  "Flauta",
  "Guitarra",
  "Guitarra Acústica",
  "Guitarra Eléctrica",
  "Mandolina",
  "Maracas",
  "Marimba",
  "Pandereta",
  "Percusión",
  "Piano",
  "Platillos",
  "Saxofón",
  "Sintetizador",
  "Trombón",
  "Trompeta",
  "Ukulele",
  "Violín",
  "Violonchelo",
  "Voz",
  "Xilófono"
];