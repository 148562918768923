import React, { useState, useEffect } from 'react';
import { Box, Container, Paper, Button, Typography, Grid, Backdrop, CircularProgress } from '@mui/material/';
import Review from './Review';
import Copyright from 'components/Copyright/Copyright';
import FinalInstructionsDialog from './FinalInstructionsDialog';
import LoadingButton from 'components/CustomButtons/LoadingButton';
import { to, toWithOutError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { paymentsCheckoutRequested, paymentsPayDelete, paymentsPayUpdateLocal, paymentsPayUpdated } from 'redux/actions/PaymentsActions';
import { isFinishedPayAndCanRefund, notMethodChoosedYet, paymentCanRefund, paymentIsFree, paymentStatusIsPendingPay } from 'utils/payments.utils';
import { useNavigate } from 'react-router-dom';
import CopyableEditText from 'components/TextField/CopyableEditText';
import { getDateWithHoursFromTs } from 'utils/timeRelated.utils';
import {
  SUB_ORDER_CANCELLED_BY_USER, SUB_ORDER_NEED_CHECK, SUB_ORDER_NEED_PROOF,
  SUB_ORDER_NEED_REFUND, SUB_ORDER_REJECTED_PROOF
} from 'variables/financial';
import { USER_INACTIVE, USER_NEED_REFUND } from 'variables/user.variables';
import { userDataUpdateRedux } from 'redux/actions/UserDataActions';
import PayProofDialog from './PayProofDialog';
import EditOrAddFieldsDialog from 'components/Dialogs/EditOrAddFieldDialog';
import { discountsUpdateActualLocal, getDiscountstByNameRedux } from 'redux/actions/DiscountsActions';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { defaultDiscountFormData } from 'factory/discounts.factory';
import { usingDiscountSubtitleText, usingDiscountText, wantToRefundSubtitle, wantToRefundTitle } from 'utils/textToShow.utils';
import { discountaIsAvailable, getInfoTexts } from 'utils/discounts.utils';
import { getUpdatedPaymentByDiscount } from 'factory/payments.factory';
import useQuery from 'customHooks/useQuery';
import { userIsActive } from 'utils/users.utils';

const Checkout = ({ paymentToShow }) => {

  const params = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isRenew = params.renew === 'true';
  const isUpgrading = params.upgrading === 'true';

  const currentUrl = window.location.href;
  const isSubscriptionView = currentUrl.includes("subscription");
  let actualPayment = useSelector(store => store.payments?.actualPayment);

  const currentUserData = useSelector(store => store.userData);

  const noNeedToPay = paymentIsFree(actualPayment);
  const canRequestRefund = paymentCanRefund(actualPayment.lastPaymentDate);

  const actualDiscount = useSelector(store => store.discounts?.actualDiscount);
  const discountIsApplied = (actualPayment.cupon && (actualPayment.cupon.name !== "" && actualPayment.cupon.name !== "MIGRATED")) ? actualDiscount.name === actualPayment.cupon.name : false;

  const discountIsAvailable = actualDiscount.ownerId;
  if (paymentToShow) actualPayment = paymentToShow;

  const preferenceData = actualPayment.preference_data;

  const [openFinalInstructions, setOpenFinalInstructions] = useState(false);
  const [payButtonState, setPayButtonState] = useState("none");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [openCheckoutLoader, setOpenCheckoutLoader] = useState(false);
  const [openLoadProof, setOpenLoadProof] = useState(false);
  const [usingDiscount, setUsingDiscount] = useState({ open: false, title: "", subtitle: [""], values: "", type: "" })
  const [buttonState, setButtonState] = useState(false);
  const [infoDialogText, setInfoDialogText] = useState({ title: "", subtitle: [""] });

  const proofRejected = actualPayment.status === SUB_ORDER_REJECTED_PROOF;
  const pendingPay = paymentStatusIsPendingPay(actualPayment.status);
  const needProof = actualPayment.status === SUB_ORDER_NEED_PROOF;
  const needCheck = actualPayment.status === SUB_ORDER_NEED_CHECK;
  const finishedPay = isFinishedPayAndCanRefund(actualPayment);
  const justPlanSelected = notMethodChoosedYet(actualPayment);
  const orderInProccessFound = justPlanSelected || needCheck || needProof || pendingPay || proofRejected;

  const handleCloseAskDiscount = () => setUsingDiscount({ ...usingDiscount, open: false });

  const handleNotUseCupon = () => {
    dispatch(discountsUpdateActualLocal(defaultDiscountFormData));
    let updatedPayment = getUpdatedPaymentByDiscount(actualPayment, defaultDiscountFormData);
    dispatch(paymentsPayUpdateLocal(updatedPayment));
  }

  const handleEditDiscountName = () => setUsingDiscount({
    open: true, title: "Ingresa el nombre del Cupón",
    subtitle: usingDiscountSubtitleText(isRenew),
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue.trim()),
    initialValues: "", handleCloseDialog: handleCloseAskDiscount, type: ""
  });

  const handleConfirmEditDiscount = async newValue => {
    setButtonState("loading");
    let getDiscountResponse = await toWithOutError(dispatch(getDiscountstByNameRedux(newValue)));
    if (getDiscountResponse === "ERROR" || (getDiscountResponse === "EMPTY" || !getDiscountResponse.id)) {
      setInfoDialogText(getInfoTexts(getDiscountResponse)); setButtonState('open-info'); return;
    }
    let isAvailable = discountaIsAvailable(getDiscountResponse, currentUserData.id, isRenew);
    if (isAvailable === "AVAILABLE") {
      dispatch(discountsUpdateActualLocal(getDiscountResponse));
      let updatedPayment = getUpdatedPaymentByDiscount(actualPayment, getDiscountResponse);
      await toWithOutError(dispatch(paymentsPayUpdated(updatedPayment)));
    }
    else { setInfoDialogText(getInfoTexts(isAvailable)); setButtonState('open-info'); return; }
    setButtonState("none");
    setUsingDiscount({ ...usingDiscount, open: false });
  }

  const handleAcceptTermsChange = event => setAcceptTerms(event.target.checked);
  const handleLoadUserPayInfo = () => setOpenLoadProof(true);

  const handleCallCheckout = async () => {
    setPayButtonState('loading');
    let errorCallingForCheckout = "";
    [errorCallingForCheckout] = await to(dispatch(paymentsCheckoutRequested(currentUserData, preferenceData, actualPayment, isRenew)))
    if (errorCallingForCheckout) {
      setPayButtonState('error');
      return;
    }
    handleToPay();
  }

  const handleToPay = () => {
    setOpenFinalInstructions(true);
    setPayButtonState('none');
  };

  const handleFinishPay = async () => {
    setAcceptTerms(false);
    setOpenFinalInstructions(false);
  };

  const handleBack = () => { setButtonState('none'); navigate("/admin/subscription"); }

  const handleCancelOrder = async refundOrCancel => {
    const isRefund = refundOrCancel === 'refund';
    setOpenCheckoutLoader(true);
    if (isRefund || ((pendingPay || needProof) && !userIsActive(currentUserData.userStatus))) {
      let userStatus = isRefund ? USER_NEED_REFUND : USER_INACTIVE;
      let noCupon = { name: "", amountArs: 0, amountUsd: 0, type: "" };
      await toWithOutError(dispatch(paymentsPayUpdated({
        ...actualPayment, cupon: noCupon, status: isRefund ? SUB_ORDER_NEED_REFUND : SUB_ORDER_CANCELLED_BY_USER
      })));
      let fromPaymentAction = true;
      let resultUpdateUser = await toWithOutError(
        dispatch(userDataUpdateRedux({ userStatus, id: actualPayment.ownerId }, fromPaymentAction)));
      if (resultUpdateUser === "ERROR") console.log("ERROR UPDATING USER DATA");
    }
    else await toWithOutError(dispatch(paymentsPayDelete(actualPayment.id)));
    handleBack();
    setOpenCheckoutLoader(true);
  }

  const handleWantToRefund = () => {
    setInfoDialogText({
      title: wantToRefundTitle, subtitle: wantToRefundSubtitle,
      handleOk: () => handleCancelOrder('refund'), okButtonText: 'Pedir Reembolso'
    });
    setButtonState("open-info");
  }

  const handleGoToDashboard = () => navigate("/admin/dashboard");

  const nextStepTextButton = () => {
    if (!justPlanSelected && needProof) return "Cargar Comprobante";
    if (needCheck || proofRejected) return "Cambiar Comprobante";
    if (noNeedToPay) return "Finalizar";
    return "Ir al Pago";
  }

  const nextStepHandler = () => {
    if (needProof || needCheck || proofRejected) handleLoadUserPayInfo();
    if (justPlanSelected) handleCallCheckout();
  }

  const getCorrectCancelButton = () => {
    if (pendingPay || needProof) return <Button onClick={() => handleCancelOrder('cancel')} sx={{ mt: 3, ml: 1 }}>Cancelar Pedido</Button>;
    if (canRequestRefund && (needCheck || finishedPay)) return <Button onClick={handleWantToRefund} sx={{ mt: 3, ml: 1 }}>Pedir Reembolso</Button>;
    return <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>Atrás</Button>;
  }

  const jsxOrderText = <><b style={cardCodeTextStyle}>Tú número de orden es: </b><b style={orderNumberTextStyle}>{actualPayment.id}</b></>

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>

      <FinalInstructionsDialog open={(!finishedPay && openFinalInstructions)} onClose={handleFinishPay}
        acceptTerms={acceptTerms} handleAcceptTermsChange={handleAcceptTermsChange} />

      <InfoActionDialog key={"info-dialog"} id='info-dialog' isOpen={buttonState === "open-info"}
        handleClose={() => setButtonState("none")} title={infoDialogText.title} contentTexts={infoDialogText.subtitle}
        handleOk={infoDialogText.handleOk} handleOkButtonText={infoDialogText.okButtonText} />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} editOptions={usingDiscount} setEditOptions={setUsingDiscount} />

      <PayProofDialog open={openLoadProof} setOpen={setOpenLoadProof} showOrLoad={'load'} />

      <Backdrop open={openCheckoutLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

        <Typography paddingBottom={2} component="h1" variant="h4" align="center">
          {isRenew ? "Renovación" : isUpgrading ? "Cambio de Plan" : "Tu Pedido"}
        </Typography>

        {orderInProccessFound && paymentToShow &&
          <Grid sx={{ mb: 1 }}>
            <b style={cardDSPNameStyles}>Hemos encontrado un pedido sin finalizar.</b>
            <br />
            {(needCheck || finishedPay) &&
              <b style={cardDSPNameStyles}>Si te arrepentiste puedes pedir el reembolso dentro de los siguientes 5 días.</b>}
            {!needCheck && <b style={cardDSPNameStyles}> Puedes continuarlo o cancelarlo.</b>}
          </Grid>}

        {!proofRejected && (pendingPay || justPlanSelected) &&
          <Grid container spacing={1} textAlign='center'>
            <Grid item xs={12}>
              <Button variant='contained' onClick={discountIsApplied ? handleNotUseCupon : handleEditDiscountName}>
                {discountIsApplied ? "No Usar Cupón" : "Usar Cupón"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {discountIsApplied && usingDiscountText(actualDiscount)}
            </Grid>
          </Grid>
        }

        {!justPlanSelected && (pendingPay || needProof) &&
          <>
            <Typography variant="h5" gutterBottom>
              Gracias por realizar tu pedido
            </Typography>
            <b style={cardCodeTextStyle}>Tienes 5 días para realizar el pago del mismo y cargar el comprobante a partir,</b>
            <br />
            <b style={cardCodeTextStyle}>{` de la fecha de pedido: ${getDateWithHoursFromTs(actualPayment.whenCreatedTS)}.`}</b>
            <br />
            <CopyableEditText textToCopy={actualPayment.id} color={'grey'} jsxElementText={jsxOrderText} />
            <b style={cardCodeTextStyle}>Te hemos enviado un mail de confirmación.</b>
          </>}

        {!justPlanSelected && !proofRejected && needCheck &&
          <>
            <Typography variant="h5" gutterBottom>
              Gracias por realizar tu pedido y cargar el comprobante
            </Typography>
            <CopyableEditText textToCopy={actualPayment.id} color={'grey'} jsxElementText={jsxOrderText} />
            <b style={cardCodeTextStyle}>Todavía necesitamos comprobar que el pago sea correcto para habilitar tu cuenta.</b>
          </>}

        {!justPlanSelected && finishedPay &&
          <>
            <Typography variant="h5" gutterBottom>
              Ya hemos recibido el pago de tu pedido
            </Typography>
            <CopyableEditText textToCopy={actualPayment.id} color={'grey'} jsxElementText={jsxOrderText} />
            <b style={cardCodeTextStyle}>Te hemos enviado un mail de confirmación.</b>
          </>}

        <>

          <Review discountIsApplied={discountIsApplied} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {(!isSubscriptionView && finishedPay)
              ? paymentToShow
                ? null
                : <Button onClick={handleGoToDashboard} variant='contained' sx={{ mt: 3, ml: 1 }}>
                  Finalizar
                </Button>
              : <>
                {getCorrectCancelButton()}
                {!finishedPay && <LoadingButton
                  buttonFullWidth={false}
                  variant="contained"
                  color="primary"
                  buttonSx={{ mt: 3, ml: 1 }}
                  onClickHandler={nextStepHandler}
                  textButton={nextStepTextButton()}
                  loading={payButtonState === 'loading'}
                  disabled={false}
                />}
              </>}
          </Box>
        </>

      </Paper>
      <Copyright />
    </Container>
  );
}

export default Checkout;

const cardCodeTextStyle = { color: "rgba(0,0,0,0.5)", whiteSpace: "nowrap", margin: "0", fontWeight: 400, fontSize: "16px", marginTop: "1em", marginBottom: "0" };
const orderNumberTextStyle = { color: "rgba(0,0,0,0.9)", whiteSpace: "nowrap", margin: "0", fontWeight: 500, fontSize: "16px", marginTop: "1em", marginBottom: "0" };
const cardDSPNameStyles = { color: "rgba(0,0,0,0.9)", margin: "0", fontWeight: 500, fontSize: "16px", marginBottom: "0" };