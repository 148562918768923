import React, { useState } from "react";
import {
  Card, CardContent, Typography, IconButton, Button, Box, Grid,
  DialogTitle, DialogContentText, Chip
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import ArtistAddedIcon from "views/Icons/ArtistAddedIcon";
import { fugaGreen } from "variables/colors";
import AddInstrumentForm from "./AddInstrumentForm";

const CollaboratorsDisplay = ({ setTrackData, trackData, disabled }) => {

  const defaultOpenAddInstruments = { open: false, collIndex: 0, collName: "", assignedInstruments: [] };
  const [openAddInstruments, setOpenAddInstruments] = useState(defaultOpenAddInstruments);

  const onHandleAddInstrument = index => {
    const collSelected = trackData.collaborators[index];
    setOpenAddInstruments({ open: true, collIndex: index, collName: collSelected.name, assignedInstruments: collSelected.instruments });
  };

  const onAddInstrument = (collName, selectedInstruments) => {
    setTrackData({
      ...trackData, collaborators: trackData.collaborators.map(coll => coll.name === collName
        ? { ...coll, instruments: selectedInstruments } : coll)
    });
  };

  const onRemoveInstrument = (collIndex, instrumentIndex) => {
    setTrackData({
      ...trackData, collaborators: trackData.collaborators.map((coll, index) => index === collIndex
        ? { ...coll, instruments: coll.instruments.filter((_, i) => i !== instrumentIndex) } : coll)
    });
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} paddingBottom={2}>
        <DialogTitle id="performers-dialog-title" sx={collaboratorsTitleStyle}>Colaboradores e Instrumentos</DialogTitle>
        <DialogContentText> Agrega los instrumentos que consideres necesario para cada colaborador.</DialogContentText>
        <DialogContentText> No es necesario que todos los colaboradores tengan un instrumento.</DialogContentText>
        <DialogContentText> Pero al menos <b>se necesita un instrumento por canción.</b></DialogContentText>
      </Grid>

      <Grid item xs={12} >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "center", alignItems: "stretch" }}>

          <AddInstrumentForm open={openAddInstruments.open} onClose={() => setOpenAddInstruments(defaultOpenAddInstruments)}
            onSave={onAddInstrument} collName={openAddInstruments.collName} assignedInstruments={openAddInstruments.assignedInstruments} />

          {trackData.collaborators.map((collaborator, collIndex) => (
            <Card key={collIndex} sx={{
              width: 250, borderRadius: 2, boxShadow: 2, display: "flex",
              flexDirection: "column", alignItems: "center", padding: 2
            }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <ArtistAddedIcon sx={artistAddedIconStyle} asIconButton={false} />

                  <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: fugaGreen, textTransform: "none" }}>
                    {collaborator.name}
                  </Typography>
                </Box>

                <IconButton>
                  <EditIcon sx={{ color: "#9E9E9E" }} />
                </IconButton>
              </Box>

              <CardContent sx={{ width: "100%", textAlign: "center", padding: 1, marginTop: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ color: "#616161", fontStyle: "italic" }}
                >
                  {collaborator.roles.join(", ")}
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 2, justifyContent: "center" }}>
                  {collaborator.instruments.map((instrument, instrumentIndex) => (
                    <Chip
                      key={instrumentIndex}
                      label={instrument}
                      color="primary"
                      onDelete={() => onRemoveInstrument(collIndex, instrumentIndex)}
                      sx={{
                        fontSize: "0.85rem",
                        fontWeight: 'bold',
                        backgroundColor: fugaGreen,
                        color: "white", // Para que el texto sea visible sobre el color
                        "& .MuiChip-deleteIcon": {
                          color: "white", // Color de la "X" para eliminar
                        },
                      }}
                    />
                  ))}
                </Box>
              </CardContent>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={collaborator.name === ""}
                sx={{ marginTop: 2 }}
                onClick={() => onHandleAddInstrument(collIndex)}
              >
                + Instrumento
              </Button>
            </Card>
          ))}

        </Box>
      </Grid>
    </Grid>
  );
};

export default CollaboratorsDisplay;

const artistAddedIconStyle = { width: "50px" };
const collaboratorsTitleStyle = { fontSize: "1.5em", textAlign: "center", paddingTop: "16px", paddingBottom: 0 };