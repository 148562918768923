import * as ReducerTypes from 'redux/actions/Types';
import * as FirestoreServices from 'services/FirestoreServices.js';
import * as BackendCommunication from 'services/BackendCommunication.js';
import { toWithOutError } from 'utils';
import { createPersonsModel } from 'services/CreateModels';
import { writeCloudLog } from '../../services/LoggingService';
import { detectRemovedCollaborators, fromCollaboratorsToContributors, getAllCollaboratorsToAttachFromUploadingTracks, getAllPeopleToCreateFromUploadingTracks, getAllPerformersToAttachFromUploadingTracks, getCollaboratorsToAddFromEditTrack, getPeopleToCreateFromEditTrack } from 'utils/collaborators';


export const addCollaborators = collaborators => {
  return {
    type: ReducerTypes.ADD_COLLABORATORS,
    payload: collaborators
  };
};

export const collaboratorsSignOut = () => {
  return {
    type: ReducerTypes.COLLABORATORS_SIGN_OUT
  }
}

export const createCollaboratorRedux = (collaborator, userId, ownerEmail) => async dispatch => {

  // Siempre debo crear el COLLABORATOR. Es unico por role y track.
  let collaboratorFromBackend = await BackendCommunication.createCollaboratorFuga(collaborator, ownerEmail, dispatch);
  if (collaboratorFromBackend === "ERROR") return "ERROR";

  collaborator.added = true;
  collaborator.ownerEmail = ownerEmail;
  collaborator.whenCreatedTS = new Date().getTime();
  collaborator.lastUpdateTS = collaborator.whenCreatedTS;

  await FirestoreServices.createElementFS(collaborator, collaborator.id, userId, "artistsCollaborators", dispatch);

  dispatch({
    type: ReducerTypes.ADD_COLLABORATORS,
    payload: [collaborator]
  });

  return collaborator;
}

export const createPerformerRedux = (performer, userId, ownerEmail) => async dispatch => {

  // Siempre debo crear el COLLABORATOR. Es unico por role y track.
  let performerFromBackend = await BackendCommunication.createPerformerFuga(performer, ownerEmail, dispatch);
  if (performerFromBackend === "ERROR") return "ERROR";

  performer.added = true;
  performer.ownerEmail = ownerEmail;
  performer.whenCreatedTS = new Date().getTime();
  performer.lastUpdateTS = performer.whenCreatedTS;

  await FirestoreServices.createElementFS(performer, performer.id, userId, "artistsPerformers", dispatch);

  dispatch({
    type: ReducerTypes.ADD_COLLABORATORS,
    payload: [performer]
  });

  return performer;
}

export const createCollaboratorsRedux = (tracksCreated, ownerId, ownerEmail) => async dispatch => {
  let onlyNewTracks = tracksCreated.filter(t => !t.existedInFuga)

  writeCloudLog(`creating people to send to fuga pre model, ownerEmail: ${ownerEmail}`
    , { arrayPeople: onlyNewTracks.map(t => t.collaborators) }, { notError: "not error" }, "info");

  const peopleToCreateFormData = createPersonsModel(getAllPeopleToCreateFromUploadingTracks(onlyNewTracks));
  writeCloudLog(`creating people to send to fuga post model, ownerEmail: ${ownerEmail}`, peopleToCreateFormData, { notError: "not error" }, "info");

  let peopleFromBackend = await BackendCommunication.createPersonsFuga(peopleToCreateFormData, dispatch);
  if (peopleFromBackend === "ERROR") return "ERROR";

  writeCloudLog(`creating people post fuga pre collaborators, ownerEmail: ${ownerEmail}`, { peopleFromBackend }, { notError: "not error" }, "info");

  let allCollaboratorsNotEmptyTracks = getAllCollaboratorsToAttachFromUploadingTracks(onlyNewTracks, peopleFromBackend, ownerId, ownerEmail);
  writeCloudLog(`all collaborators to attach post people fuga, ownerEmail: ${ownerEmail}`, { collsArray: allCollaboratorsNotEmptyTracks }, { notError: "not error" }, "info");

  let allPerformersNotEmptyTracks = getAllPerformersToAttachFromUploadingTracks(onlyNewTracks, peopleFromBackend, ownerId, ownerEmail);
  writeCloudLog(`all performers to attach post people fuga, ownerEmail: ${ownerEmail}`, { performersArray: allPerformersNotEmptyTracks }, { notError: "not error" }, "info");

  let responseCreatingAllCollaborators = [];
  for (const dataCollaborator of allCollaboratorsNotEmptyTracks) {
    if (!dataCollaborator.person) continue;
    let collaboratorCreatedResult = await toWithOutError(dispatch(createCollaboratorRedux(dataCollaborator, ownerId, ownerEmail)));
    if (collaboratorCreatedResult !== "ERROR") responseCreatingAllCollaborators.push(collaboratorCreatedResult);
  }

  let responseCreatingAllPerformers = [];
  for (const dataPerformer of allPerformersNotEmptyTracks) {
    if (!dataPerformer.person_id) continue;
    let performerCreatedResult = await toWithOutError(dispatch(createPerformerRedux(dataPerformer, ownerId, ownerEmail)));
    if (performerCreatedResult !== "ERROR") responseCreatingAllPerformers.push(performerCreatedResult);
  }

  return allCollaboratorsNotEmptyTracks.length === responseCreatingAllCollaborators.length ? "SUCCESS" : "ERROR";
}

export const updateCollaboratorsInEditRedux = (newCollaborators, originalFugaColls, trackFugaId, ownerEmail, ownerId) => async dispatch => {
  // CHEQUEAR QUE COLABORADORES YA EXISTEN.
  let trimmedNewCollabs = newCollaborators.map(collab => { return { ...collab, name: collab.name.trim() } });
  let noEmptyNewCollabs = trimmedNewCollabs.filter(collab => collab.name !== "");

  let finalFugaContributors = fromCollaboratorsToContributors(noEmptyNewCollabs, originalFugaColls);
  let fugaContributorsToDelete = detectRemovedCollaborators(noEmptyNewCollabs, originalFugaColls);

  console.log("FINAL FUGA CONTRIBUTORS: ", finalFugaContributors);
  console.log("DELETE FUGA CONTRIBUTORS: ", fugaContributorsToDelete);

  // MANDAR LAS PERSONAS QUE HAYA QUE CREAR: { name }
  let peopleToCreateArray = getPeopleToCreateFromEditTrack(finalFugaContributors);
  console.log("PEOPLE TO CREATE: ", peopleToCreateArray);
  let peopleFromBackend = await BackendCommunication.createPersonsFuga(createPersonsModel(peopleToCreateArray), dispatch);
  if (peopleFromBackend === "ERROR") return "ERROR";

  // MANDAR SOLO LOS COLABORADORES QUE HAYA QUE CREAR 
  let allCollsToCreate = getCollaboratorsToAddFromEditTrack(
    finalFugaContributors, peopleFromBackend, trackFugaId, ownerId, ownerEmail
  );

  console.log("ALL COLLS TO CREATE: ", allCollsToCreate);
  let responseCreatingAllCollaborators = [];
  for (const dataCollaborator of allCollsToCreate) {
    if (!dataCollaborator.person) continue;
    // PERSON es el ID no el OBJETO, coll debe tener ROLE, PERSON, y trackFugaId
    let collaboratorCreatedResult = await toWithOutError(dispatch(createCollaboratorRedux(dataCollaborator, ownerId, ownerEmail)));
    if (collaboratorCreatedResult !== "ERROR") responseCreatingAllCollaborators.push(collaboratorCreatedResult);
  }

  // ELIMINAR LOS COLABORADORES QUE HAYA QUE ELIMINAR
  let responseDeletingCollaborators = [];
  for (const collToDelete of fugaContributorsToDelete) {
    if (!collToDelete.id) continue;
    let collaboratorDeleteResult = await BackendCommunication.deleteCollaboratorFugaByIdAndTrack(collToDelete.id, trackFugaId, ownerEmail, dispatch);
    if (collaboratorDeleteResult !== "ERROR") responseDeletingCollaborators.push(collaboratorDeleteResult);
  }

  return (allCollsToCreate.length === responseCreatingAllCollaborators.length &&
    fugaContributorsToDelete.length === responseDeletingCollaborators.length) ? "SUCCESS" : "ERROR";
}