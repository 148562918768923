import Danger from 'components/Typography/Danger.js';
import Warning from 'components/Typography/Warning';
import { Link, Typography, DialogContentText, DialogTitle } from '@mui/material';
import { getPlanPriceInUsd } from './payouts.utils';
import { dangerColor } from '../variables/colors';
import CopyableEditText from 'components/TextField/CopyableEditText';
import { isEngineer, isLyricist } from './collaborators';

export const publicationDateWarning = [
  "Elegí la fecha en la que querés que este lanzamiento sea publicado en las DSPs. Si elegís la fecha de hoy, o mañana, no significa que tu lanzamiento va a estar disponible inmediatamente. Se procesará con la fecha que seleccionaste pero según la demanda, los lanzamientos pueden demorar hasta 1 - 2 días en aprobarse y procesarse, a la vez las DSPs tienen tiempos variables, y por último puede haber errores o que necesitemos corregir aspectos de tu lanzamiento.",
  < br />, "Por lo que: Si es muy importante que tu álbum se publique en una fecha exacta del futuro(por ej, para una campaña promocional), recomendamos trabajar y seleccionar una fecha con al menos 14 días de anticipación, en la cual podemos asegurarte que estará disponible en la mayoría de las DSPs principales a la vez.",
  < br />, "Si es tu primer lanzamiento(y aún no tenés perfil en las DSPs) recomendamos que elijas una fecha de acá a 5 - 7 días en el futuro para que tu perfil se cree correctamente.",
];

export const titleInvalidOldReleaseDate = "Fecha de publicación original inválida:";
export const titleInvalidPreCompraDate = "Fecha de pre-compra inválida:";

export const invalidDateContentText = [<Danger>La fecha debe ser menor a la actual fecha de lanzamiento.</Danger>,
<Danger>Por favor, vuelve a seleccionar la fecha indicadada.</Danger>]

export const invalidIsrcWarning = [
  <p style={{ fontSize: '16px', color: dangerColor[0], fontWeight: 600 }}>El formato del ISRC es inválido. (Formato: CC-XXX-00-12345)</p>
]

export const noAssignedInstruments = [
  <p style={{ fontSize: '16px', color: dangerColor[0], fontWeight: 600 }}>Debés asignar al menos un instrumento a algún colaborador.</p>
]

export const invalidCollNames = [
  <p style={{ fontSize: '16px', color: dangerColor[0], fontWeight: 500 }}>Alguno de los nombres de colaboradores, no tienen nombre y apellido. Detectamos que el nombre no tiene dos palabras. Por favor, corregir (si crees que es un error, contáctanos).</p>
]

export const invalidCollRoles = [
  <p style={{ fontSize: '16px', color: dangerColor[0], fontWeight: 500 }}>No tienes los roles requeridos (Compositor y/o Liricista y/o Ingeniero/Productor)</p>
]

export const isrcNotExistsOrNotOwnerText = [
  <p style={{ fontSize: '16px', color: dangerColor[0], fontWeight: 600 }}>
    El código ISRC no existe, o el artista principal de la canción no coincide con tus artistas.
  </p>
]

export const noTracksWarningTitle = "No puedes crear un lanzamiento sin canciones:";
export const noTracksWarningText = [<Danger>Por favor, ingresa en la tabla las canciones del lanzamiento.</Danger>];

export const noDateRelease = "Falta seleccionar la fecha del lanzamiento:";
export const noDateWarningText = [<Danger>Por favor, ingresa el día, mes y año del lanzamiento.</Danger>];

export const singleTrackDifferentNamesTitle = "Un Single no puede tener un nombre distinto al nombre de la única canción";
export const singleTrackDifferentNamesText = [<p style={{ fontSize: '16px', color: '#f44336' }}>Al ser un lanzamiento de una sola canción, el nombre debe ser igual al de la canción</p>,
  "Nosotros corregiremos eso. Por lo que el nombre del lanzamiento será igual al nombre de la canción.",
  "La carga del lanzamiento comenzará automáticamente."
]

export const singleTrackDifferentVersionsTitle = "Un Single no puede tener una versión distinta a la versión de la única canción";
export const singleTrackDifferentVersionsText = [<p style={{ fontSize: '16px', color: '#f44336' }}>Al ser un lanzamiento de una sola canción, la versión debe ser igual al de la canción</p>,
  "Nosotros corregiremos eso. Por lo que la versión del lanzamiento será igual a versión de la canción.",
  "La carga del lanzamiento comenzará automáticamente."
]

export const noCoverTitle = "Falta el Arte de Tapa. Si aún asi la vez, cárgala nuevamente";
export const noCoverWarningText = [<Danger>Por favor, vuelve a seleccionar un Arte de Tapa.</Danger>];

export const featuringArtistTooltip = [
  "Indica si el Artista será Principal o Featuring.",
  "Presionar para más información."
]

export const maxArtistsText = [
  <Warning>{`Ya tienes el máximo número de Artistas que tu Plan te permite.`}</Warning>,
  "por favor contáctanos a: ", <b>soporte@laflota.com.ar</b>, " para que puedas obtener un plan con más artistas."
]

export const needAdminPermissionsText = ["No puedes realizar esta acción, no tienes permisos de Administrador."]

export const resourceNotYoursText = ["No puedes realizar esta búsqueda, el recurso que intentas buscar o al menos uno de ellos no te pertenece o no existe.",
  "Por favor corrobora que está bien escrito."]

export const emptyRoyaltiesResult = ["No se encontraron regalías para este usuario.", "No dispone de Artistas en la App."]
export const emptyPaysResult = ["No se encontraron pagos para este usuario."]

export const editTrackArtistsInfo = [
  "Recuerda que tu artista principal tiene que aparecer en la canción. ",
  "Puedes agregar o eliminar otros artistas pero siempre debe haber al menos uno que sea Principal. (próximamente)", <br />,
  "Si el lanzamiento es un Single, la configuración de los artistas a nivel lanzamiento debe ser la misma, ",
  "y puedes cambiarla desde el lanzamiento, no desde esta ventana (próximamente)."
];

const collaboratorsTitleStyle = { fontSize: "1.5em", textAlign: "center", paddingTop: "16px", paddingBottom: 0 };
export const collaboratorsNewTrackText = <>
  <DialogTitle id="collaborators-dialog-title" sx={collaboratorsTitleStyle}>Colaboradores de la Canción</DialogTitle>
  <DialogContentText>Ingresa el <b>nombre y apellido</b> de lxs colaboradorxs.</DialogContentText>
  <DialogContentText>Si tu nombre de artista es tu nombre y apellido igual tienes que agregarte como colaborador.</DialogContentText>
  <DialogContentText> No se aceptan nombres artísticos, nombres de bandas, apodos ni abreviaciones.</DialogContentText>
  <DialogContentText>Necesitas al menos un colaborador que tenga rol Compositor y otro (que puede ser el mismo) que tenga el rol Liricista (a menos que la cancion sea instrumental)</DialogContentText>
</>

export const newTrackArtistsInfo = [
  "Lxs artistas que mencionaste en el lanzamiento ahora deben tener ",
  "un rol obligatorio dentro de cada canción que les corresponda, por lo que ",
  "debes seleccionar cuales son artistas principales o featuring de la canción ",
  "o puedes eliminarlos para que no aparezcan en ésta canción (debe haber al menos un artista principal)."];

export const newTrackArtistsInfoTrackExists = ["Estos son lxs artistas que mencionaste en la canción cuando la creaste. ",
  "Como es una canción que ya está publicada, no puedes cambiarlxs.", <br />,
  "Si quisieras cambiarlxs, debés editar la canción original primero. Contactándote con Soporte."];

export const changeOrResetIsrcSearch = ["Si no quieres usar ésta canción y prefieres crear una nueva, ",
  "vuelve a hacer click en el cuadrado de arriba para desactivarlo, ", <br />,
  "y luego haciendo click en NO TENGO ISRC, o cambia de código ISRC"
]

export const assetVersionsHelper = ["Puedes especificar si la canción es una versión de otra.", <br />,
  "Por Ejemplo, una versión En Vivo, Acústico, Remix, etc.", <br />,
  "No son valores válidos los que incluyan los siguientes términos: Estudio, Original, Exclusive...", <br />,
  "Si el lanzamiento tiene una sola canción, la versión de la canción no puede ser diferente a la del lanzamiento.", <br />,
  <br />, <b>No se admiten carácteres como: ), (, [, ], etc.</b>];

export const warningIfTrackExists = [
  "Si realizaste un lanzamiento en La Flota que incluía ésta canción no debes crearla ",
  "nuevamente. Solo ingresa su código ISRC y la agregaremos.",
  <br />, <b>Si no conoces el ISRC de la canción puedes buscarlo en el lanzamiento que incluye a la canción que quieres agregar. </b>,
  <b>En el listado de canciones del lanzamiento, aparecerá el código ISRC de todas las canciones que incluye.</b>,
  <br />, <br />, "Formato: CC-XXX-00-12345 o CCXXX0012345."
]

export const infoSpotifyUri = [
  "Ingresa el código URi de Spotify.", <br />,
  "Dejar vacío para crear uno nuevo.", <br />,
  "Ejemplo: spotify:artist:1JTD8FzXyY8Pk3lX1FIkpG", <br />,
  "Haz click para más información."
];

export const spotifyUriNotValidText = [
  "El formato del Spotify Uri es inválido.", <br />,
  "Formato: ", <b>spotify:artist:2ERtLJTrO8RXGMAEYOJeQc</b>, < br />,
  "que contiene exactamente 37 caracteres. Dejar vacío si no lo conoces."
]

export const spotifyUriNotValidTextDialog = [
  "El formato del Spotify Uri es inválido.",
  <p>Formato: </p>, <b>spotify:artist:2ERtLJTrO8RXGMAEYOJeQc</b>,
  "que contiene exactamente 37 caracteres.",
  "Dejar vacío si no lo conoces."
]

export const infoHelperTextAppleId = [
  "Si tenes el Apple ID del perfil de Artista donde queres que subamos la música, ingresalo.", <br />,
  "Puedes encontrarla en tu perfil en iTunes (son los últimos dígitos de la URL de tu perfil).", <br />,
  <br />, <b>Por ejemplo: 1536584137</b>
]

export const appleCriteriaTitle = "Has seleccionado Apple Music";

export const preSaleCheckBoxHelper = [
  "Podés permitir que tus seguidores compren tu trabajo en iTunes, Amazon y Google Play antes de la fecha del lanzamiento", <br />,
  "(les llegará el álbum el día del lanzamiento).Es ideal para generar campañas de marketing alrededor de la fecha de lanzamiento. ", <br />,
  " Para que funcione debés elegir una fecha de inicio de Pre - Compra anterior a la fecha del lanzamiento que seleccionaste.La Fecha de ", <br />,
  " Lanzamiento debe ser de al menos 10 días en el futuro.Por ej: si la fecha de lanzamiento que seleccionaste es en 10 días desde hoy, el Pre - Order podría iniciar en 5 días desde hoy."
];

export const contentTextDspsDialogReleased = ["Si querés que tu lanzamiento aparezca en otras DSP's, podés seleccionarlas acá.", <br />,
  "En cambio, si querés dar de baja el lanzamiento en una DSP en la que ya esté online el lanzamiento, ", <br />,
  "debés deseleccionarla y continuar."
]

export const contextTextDspsDialogNotReleased = ["Selecciona las DSP's a donde deseas enviar tu lanzamiento."];

export const oldReleaseCheckBoxHelper = ["Si el lanzamiento ya fue publicado alguna vez y querés mostrar la fecha original del lanzamiento."]

export const lanzamientoColaborativoTooltip = [
  "Seleccioná si el lanzamiento pertenece a dos o más artistas.", <br />,
  "A diferencia de agregar un artista como invitado en una canción (Featuring),", <br />,
  "esta opción publicará el álbum en los perfiles de cada uno de los artistas y pertenecerá a ambos.", <br />,
  "Presionar para ver Ejemplo de un Lanzamiento con dos Artistas en Spotify."
]

export const albumCoverHelperText = [
  "El arte de tapa debe ser una imagen de alta calidad.", < br />,
  "El archivo debe ser JPG de colores RGB de mínimo 3000 * 3000px y un máximo de 6000px * 6000px y siempre debe ser CUADRADA", < br />,
  "(si necesitás ayuda consultá a tu diseñador o avisanos y te recomendamos diseñadores que trabajan con nosotros)."
]

export const deleteAlbumDialogText = "Confirma que quieres eliminar el Lanzamiento. Se eliminarán todas las canciones que lo componen y se dará de baja de todas las DSPs.";

export const deleteArtistDialogText = "Confirma que quieres eliminar el Artista. Si no tiene lanzamientos estrenados, el Artista se eliminará completamente, si no pasará a estado INACTIVO";

export const deleteUserDialogText = ["Confirma que quieres eliminar el Usuario.",
  <ul>
    <li>Se eliminarán todos sus Artistas y Lanzamientos.</li>
    <li>Si un Artista tiene lanzamientos online, no se eliminará, su estado pasará a INACTIVO.</li>
    <li>Lo mismo para los lanzamientos que hayan estado online, se darán de baja.</li>
  </ul>
]

export const getNumeracionOrdinalFromIndex = [
  "Primer", "Segundo", "Tercer", "Cuarto", "Quinto", "Séptimo", "Octavo", "Noveno", "Décimo", "Undécimo", "Duodécimo", "Decimotercer",
  "Decimocuarto", "Decimoquinto", "Decimosexto", "Decimoséptimo", "Decimoctavo", "Decimonoveno", "Vigésimo"
]

export const getHelperCollaboratorText = (indexCollaborator, audioLocale) => {
  if (indexCollaborator === 0) return "Ingresa el nombre y apellido (solo uno, si quieres agregar más, haz click en el checkbox de abajo) del Compositor/a/x como quieras que aparezca en las DSPs. Si o sí, tiene que haber al menos un Compositor.";
  if (isLyricist(indexCollaborator, audioLocale)) return "Ingresa el nombre y apellido (solo uno, si quieres agregar más, haz click en el checkbox de abajo) del Liricista como quieras que aparezca en las DSPs. Si o sí, tiene que haber al menos un Liricista.";
  if (isEngineer(indexCollaborator, audioLocale)) return "Ingresa el nombre y apellido (solo uno, si quieres agregar más, haz click en el checkbox de abajo) del Productor/Ingeniero como quieras que aparezca en las DSPs. Si o sí, tiene que haber al menos un Productor/Ingeniero.";
  if (indexCollaborator >= 2) return "Ingresa el nombre y apellido (solo uno, si quieres agregar más, haz click en el checkbox de abajo) como quieras que aparezca en las DSPs. Dejar vacío si no quieres agregarlo. ";
  return "";
}

export const releaseDateInfoTooltip = [
  "Elije la fecha en la que este lanzamiento será publicado en las DSP's. ",
  "Para lanzamientos programados recomendamos 10 días de anticipación. ",
  "El tiempo medio es de 12 a 72 horas hábiles para la mayoría de las DSP's, ",
  "algunas como Facebook Music o YouTube pueden demorar un poco más."
]

export const bienvenidoDialogText = [
  "Por favor, aguarda unos instantes mientras enlazamos tus Artistas cargados en el sistema anterior.",
  "A partir de ahora puedes crear, editar, publicar y gestionar tu arte y trabajo desde un sólo lugar! Los lanzamientos creados anteriormente los puedes encontrar en el panel anterior, hasta que terminamos la migración.",
  "Esperamos que les guste ♡", <br />, <br />, "Equipo de La Flota"
];

export const imageConstraintsMessage = [
  "La imagen debe ser formato JPG, CUADRADA y debe tener una resolucion mínima de 3000*3000 píxeles",
  " y una resolución máxima de 6000*6000 píxeles con un tamaño máximo de 20mb"
]

export const textLowQualityAudio = [
  "El archivo de audio tiene una calidad muy baja.",
  "Debe tener formato WAV, stereo, de 16, 24, o 32 bits y con un Sample Rate entre 44.1 y 192 kHz."
]

export const textFloatingPointAudio = [
  "Archivos Floating point WAV no son soportados.",
  "Debe tener formato WAV, stereo, de 16, 24, o 32 bits y con un Sample Rate entre 44.1 y 192 kHz"
]

export const filesMissingTitle = 'Archivos de audio faltantes';
export const publishedSuccessTitle = 'Tu lanzamiento ya se encuentra aprobado y listo para ser entregado a las DSPs';
export const deliveredSuccessTitle = "¡Felicitaciones! Tu lanzamiento ya está en camino a las DSP's 🌐";
export const deliveredAppleSuccessTitle = "¡Felicitaciones! Tu lanzamiento ya está en camino a Apple Music 🌐";
export const errorDeliveredTitle = 'Hubo un error al publicar el lanzamiento';
export const redeliveredSuccessTitle = "¡Felicitaciones! Redelivery realizado con éxito";

export const filesMissingText = (filesMissing, tracks) => {
  let trackNamesString = "";
  filesMissing.forEach((upload, index) => {
    let trackTitle = tracks.find(track => track.fugaId === upload.fileId)?.title || "";
    if (index === filesMissing.length - 1) trackNamesString = trackNamesString + trackTitle;
    else trackNamesString = trackNamesString + trackTitle + ", "
  });

  return [
    <Warning sxOverride={{ fontSize: '18px', fontWeigth: '600' }}>
      {'Tu lanzamiento se ha generado en nuestra plataforma, pero hubo errores al subir los archivos de audio para las siguientes canciones:'}
    </Warning>, <br />,
    <b>{trackNamesString}</b>, <br />,
    "Por favor, vuelve a cargar los archivos de las canciones que mencionamos en la siguiente pantalla. ",
    "Debés darle click al botón de EDITAR. Y ahí podrás cargar un nuevo archivo y una vez terminada la carga, ",
    "deberás hacer click en GUARDAR y REDISTRIBUIR."
  ]
}

export const tracksFugaMissingText = tracksFugaMissing => {
  let trackNamesString = "";
  tracksFugaMissing.forEach((trackFuga, index) => {
    if (index === tracksFugaMissing.length - 1) trackNamesString = trackNamesString + trackFuga.name;
    else trackNamesString = trackNamesString + trackFuga.name + ", "
  });

  return [
    <Warning sxOverride={{ fontSize: '18px', fontWeigth: '600' }}>
      {'Todavía detectamos que faltan archivos de audio para las siguientes canciones:'}
    </Warning>, <br />,
    <b>{trackNamesString}</b>, <br />,
    "Por favor, vuelve a cargar los archivos de las canciones que mencionamos", <br />,
    " o si ya lo has intentado, puedes enviarlas siguiendo el siguiente link: ", <br />,
    weTransferLink,
  ]
}

export const weTransferLink = <Link
  sx={{ fontSize: '18px', fontWeigth: '600' }}
  href="https://wetransfer.com/?to=info@laflota.com.ar"
  target="_blank"
  variant="body2"
  underline="hover">
  WeTransfer
</Link>

// VIejo
// export const filesMissingText = (filesMissing, tracks) => {
//   console.log("FILES MISSING ANDS TRACKS: ", filesMissing, " / TRACKS: ", tracks);
//   let trackNamesString = "";
//   filesMissing.forEach((upload, index) => {
//     let trackTitle = tracks.find(track => track.fugaId === upload.fileId)?.title || "";
//     if (index === filesMissing.length - 1) trackNamesString = trackNamesString + trackTitle;
//     else trackNamesString = trackNamesString + trackTitle + ", "
//   });

//   return [
//     <Warning sxOverride={{ fontSize: '18px', fontWeigth: '600' }}>
//       {'Tu lanzamiento se ha generado en nuestra plataforma, pero hubo errores al subir los archivos de audio para las siguientes canciones:'}
//     </Warning>, <br />,
//     <b>{trackNamesString}</b>, <br />,
//     "Por favor, envía los archivos de las canciones que mencionamos al siguiente link de WeTransfer, ",
//     "que el nombre de cada archivo se corresponda con el nombre de la canción ",
//     "y el título del mensaje sea el nombre del Lanzamiento", <br />,
//     <Link
//       sx={{ fontSize: '18px', fontWeigth: '600' }}
//       href="https://wetransfer.com/?to=info@laflota.com.ar"
//       target="_blank"
//       variant="body2"
//       underline="hover">
//       WeTransfer
//     </Link>,
//   ]
// }

export const publishedSuccessText = ['Tu lanzamiento se ha publicado correctamente, pronto estará en camino a las DSPs'];

export const deliveredSuccessText = [
  "Este proceso tiene una media de demora de 1 a 5 días hábiles en la mayoría de las DSP's, ",
  "luego depende del caudal de procesamiento de cada una. Algunas, como Facebook Music (Instagram) ",
  "o YouTube demoran un poco más. Para lanzamientos programados recomendamos al menos 10 días ",
  "de antelación para corregir eventuales inconvenientes y asegurar el lanzamiento en todas las DSP's en la fecha elegida.",
];

export const redeliveredSuccessText = [
  "Este proceso tiene una media de demora de 1 a 5 días hábiles en la mayoría de las DSP's, luego depende del caudal de procesamiento de cada una. Algunas, como Facebook Music (Instagram) o YouTube demoran un poco más."
];

export const errorDeliveredText = ["Tu lanzamiento ya se encuentra en etapa de de revisión"];

export const warningAppleDelivery = [
  "Si quieres que tu música sea enviada a Apple Music,", <br />,
  "el lanzamiento debe cumplir con ciertos requisitos,", <br />,
  "y el tiempo de aprobación si no cumple las normas puede ser mayor", <br />,
]

export const warningFBIGDelivery = [
  <b>{"Tu lanzamiento puede ser rechazado por FB e IG si cumple con una o más de estas características: "}</b>,
  <ul>
    <li>Efectos de sonido</li>
    <li>Sound - alikes - Sonidos parecidos / Imitaciones de otros artistas</li>
    <li>Grabaciones de karaoke</li>
    <li>Bucles de producción</li>
    <li>Grabaciones de música clásica y composiciones de dominio público</li>
    <li>Meditación, yoga o música para dormir</li>
    <li>Sets de DJ o mezclas continuas</li>
    <li>Grabaciones de palabras habladas</li>
    <li>Grabaciones de comedia</li>
    <li>Grabaciones de películas(que no son la partitura musical de una película)</li>
    <li>Discursos</li>
    <li>Grabaciones de oración</li>
    <li>Audiolibros</li>
    <li>Podcasts</li>
    <li>Grabaciones de naturaleza o vida salvaje</li>
    <li>Grabaciones de sonido ambiental</li>
    <li>Grabaciones o descripciones de sexuales</li>
  </ul>
]

export const warningYTDelivery = [
  <b>{"Ten en cuenta que tu lanzamiento puede ser rechazado por YouTube si se encuentra dentro de alguna de estas categorías: "}</b>,
  <ul>
    <li>"lo mejor de/Best Off" y compilaciones</li>
    <li>Mashups, mezclas de dj y remixes de otros trabajos</li>
    <li>Música o vídeo sobre los que posee derechos no exclusivos</li>
    <li>Grabaciones de actuaciones (incluidos conciertos, eventos, discursos, espectáculos)</li>
    <li>Contenido de dominio público</li>
    <li>Contenido para karaoke</li>
    <li>Efectos de sonido y sonidos genéricos</li>
    <li>Regrabaciones de composiciones clásicas</li>
    <li>Música y vídeo sin licencia.</li>
  </ul>
]

export const subtitleEditUPC = [
  "Este cambio claramente no genera un Redelivery, ", <br />,
  "ya que no debería estar en Delivery si podemos cambiar el UPC.", <br />,
  "Se supone que sólo debemos agregar o editar un UPC cuando por algun motivo,",
  "el UPC que aparece en la APP no concuerda con el de FUGA.", <br />,
  <b>{'El cambio solo impacta a nivel de la APP'}</b>]

export const subtitleEditFugaId = ["Útil para cuando creamos por nuestra cuenta un lanzamiento en FUGA y el lanzamiento que se ve en la APP no esta enlazado a ese Lanzamiento."]

export const addAlbumWithFugaIdToArtistsSubtitle = ["Útil para cuando el lanzamiento existe en FUGA, ", <br />,
  "pero por alguna razón no está en la app. "]

export const waitForRoyalties = [<b>{"Estamos obteniendo tus ingresos desde nuestros servidores."}</b>];

// PAYOUTS
export const waitForPayouts = [<b>{"Estamos obteniendo tus pagos desde nuestros servidores."}</b>];

export const payoutLessThanTen = plan => ["No podés realizar un retiro menor a 10 dolares.",
  "Podés volver a intentar cuando hayas generado un monto superior.",
  `Sí podés usar las regalías como un cupón de pago para renovar tu cuenta (mínimo de 1 USD y cómo máximo el precio de tu suscripción: ${getPlanPriceInUsd(plan)} USD.).`];

export const emailsNoEquals = ["Los mails de tu cuenta que elegiste como medio de pago, no coinciden.",
  "Por favor, revisa, e intenta nuevamente."];

const getTextToCopyCupon = text => {
  return <b style={{ textWeight: 500 }}>{text}</b>
}
export const payoutGenerated = (accountType, cuponName) => {

  if (accountType === "cupon") return [
    "Tu cupón ya está disponible:", <br />,
    <CopyableEditText textToCopy={cuponName} color={'black'} jsxElementText={getTextToCopyCupon(cuponName)} />, <br />,
    <b>En el menú lateral izquierdo, haciendo click en Cupones también podrás verlo.</b>,
    "Para usarlo, ingresa el nombre del Cupón que aparece arriba (puedes copiarlo haciendo click en el icono de 'copiado') cuando quieras realizar el pago de tu suscripción."
  ]
  return ["La transferencia se realizará durante los próximos 10 días."]
}

export const otpDialogTitle = "Hemos enviado un codigo OTP a tu email";
export const otpDialogSubitle = ["Por favor, ingresa ese código para poder verificar tu identidad.", <br />,
  "En caso de no encontrarlo en tu casilla de Recibidos, búsca en Spam.", <br />,
  "En última instancia puedes enviarnos un mail a: ", <b>soporte@laflota.com.ar</b>, <br />,
  "confirmando el retiro de dinero, desde el email de tu cuenta de La Flota."];

export const loginOtpDialogSubitle = ["Hemos enviado un código OTP (One-Time-Password) a tu casilla de email para verificar tu identidad.", <br />,
  "En caso de no encontrarlo en la bandeja de entrada, verifica en Spam/Correo no deseado.", <br />,
  "Si no llegó correctamente, por favor contáctanos a: ", <b>soporte@laflota.com.ar</b>];

export const completePayoutSubtitle = ["Completa con el ID del Pago."];
export const addMpIdSubtitle = ["Completa con el ID de Mercado Pago del Pago.",
  "Este ID sólo lo veremos nosotros, no el usuario."];

export const dashboardUserTitle = "Te damos la bienvenida a La Flota ♫";
export const dashboardAdminTitle = "La Flota Admin";

export const taxesWarning = ["Desde Enero de 2022, la IRS de EEUU percibe el 30% de lo generado por streams, ",
  "descargas y ventas dentro de su territorio. Este impuesto afectará a los lanzamientos ",
  "publicados en el sistema anterior, hasta el 16 de Febrero de 2022 y estará vigente hasta los ",
  "reportes de Octubre (actividad de Julio) del 2022, ya que realizamos una modificación en ",
  "nuestra línea de distribución y por momento evitamos este impuesto."
]

export const versionsHelper = ["Puedes especificar si el lanzamiento es una versión de otro lanzamiento.", <br />,
  "Por Ejemplo, una versión En Vivo, Acústico, Remix, etc.", <br />,
  "No son valores válidos los que incluyan los siguientes términos: Estudio, Original, Exclusive...", <br />,
  <br />, <b>Haz click para ver un ejemplo.</b>];

export const titleAppleReject = ["Menciona las razones de porque no es apto para Apple"];
export const subtitleAppleReject = ["Se enviará un mail al usuario, con las observaciones que le hagas."];

export const tooltipoTrackExistsInFuga = "Si la canción existe en otro lanzamiento que hayas realizado en La Flota, solo deberás agregar su código ISRC.";
export const labelCheckboxTrackExistsInFuga = <b>¿La canción ya fue lanzada en La Flota anteriormente?</b>;

export const deliveryActionsSuccess = [["Pronto se verán reflejadas las acciones que has llevado a cabo."]];
export const deliveryActionsErrorText = ["Error al realizar las operaciones de Delivery", <br />,
  "Por favor, consultar con soporte técnico por mail."]

export const postChangeStateToInfractions = stateString => ["Has cambiado el estado del artista a: ", <b>{stateString}</b>, <br />,
  <b>El artista no podrá realizar nuevos lanzamientos, ni editar este artista.</b>
]

export const tooltipOrderByCreationDate = "Ordenar los lanzamientos por orden de Fecha de Creación.";

export const deleteUserDialogContentText = [
  "Confirma que deseas eliminar al usuario. ", <br />,
  "Se procederá a dar de baja todos sus lanzamientos. ", <br />,
  "Y a cambiar el estado de la suscripción y los artistas."
]

export const upcWarningText = [
  "Completa sólo si ya tienes un código UPC que quieras usar con este lanzamiento que no haya sido utilizado previamente.",
  "Si no tienes le asignaremos uno."
]

export const showConfirmationUpdatePasswordText = "Se generará una contraseña aleatoria y se la cambiará automáticamente. Copia la misma ya que no se podrá ver nuevamente."
export const showConfirmationUpdatePasswordTitle = "Confirmación de cambio de Contraseña";

//===================================================DISCOUNTS===============================================================\\

export const usingDiscountSubtitleText = isRenew => isRenew
  ? ["Es el código de tu cupón obtenido por regalías o invitar amigos. Como estás renovando, no puedes usar cupones que te hayan mandado amig@s"]
  : ["Es el código que te mandó aquella persona que te está invitando o de tu propio cupón obtenido por regalías o invitar amig@s."];

export const discountChangeStateSubtitle = ["Mientras el estado sea Activo, el cupón podrá ser usado,", <br />,
  "si se cambia a Inactivo o Reclamado, ya no podrá ser usado"];

export const discountChangeNameSubtitle = ["Recuerda que el nombre del cupón es el que deberá usar el Usuario."]

export const deleteDiscountDialogText = ["Confirma que quieres eliminar el Cupón.",
  <ul>
    <li>Si un usuario era dueño o beneficiario de este cupón, <br /> éste no se eliminará, su estado pasará a INACTIVO.</li>
    <li>Si no fue usado o asignado se eliminará completamente.</li>
  </ul>
]

export const discountChangeCantSubtitle = ["Cambia la cantidad de Cupones disponibles.", <br />,
  "Máximo de 300, ya que implica crear un documento por cada cupon.", <br />,
  "Si reduces la cantidad, solo se podrá realizar si no se han usado esos cupones."]

export const discountChangeCantUsedSubtitle = ["Cambia la cantidad de Cupones usados."]

export const discountPercentageOrAmountSubtitle = ["Elige entre descuento por Porentage o Monto Fijo"];

export const discountChangeTypeSubtitle = ["Elige entre diferentes tipos de cupones: ",
  <ul>
    <li>Canje Regalías: Cuando el usuario canjea plata generada por regalías.</li>
    <li>Canje por Upgrade: Se toma la cantidad de días que le restan del plan actual para pagar un Upgrade de plan.</li>
    <li>Carrito: cuando el usuario se hizo usuario pero no pagó dentro de los 5 días.</li>
    <li>Invitación Amigos: dinero por haber invitado a amigos.</li>
    <li>Contratos: Cuando damos cupones por haber pactado con un grupo de posibles usuarios, como con DigitalFader.</li>
    <li>VIP: Para usuarios VIP (?</li>
    <li>Otros: Cuando no es ninguno de los otros tipos.</li>
  </ul>
]

export const discountChangeOwnerEmail = ["El dueño de un Cupón es aquel que se lo ganó o generó.", <br />,
  "Por ejemplo, si un usuario invitó a amigos, el dueño del cupón que se genere será él y el beneficiario el que lo use", <br />,
  "También, si alguien genera un cupón por regalías o upgrade, el dueño será él.", <br />,
  "Puede no ser usuario de La Flota, como en el caso de pactar con algun grupo que no sea parte de La Flota."
]

export const discountChangeclaimersEmail = ["El beneficiario de un Cupón es el que efectivamente usará el mismo.", <br />,
  "Por ejemplo, si un usuario invitó a amigos, el dueño del cupón que se genere será el que invitó y el beneficiario el que lo use.", <br />,
  "Puede ser que el dueño y el beneficiario sean la misma persona, caso de canje de regalías o upgrade."
]

export const discountsInfoHowToUseThem = [
  "Puedes usar los cupones para pagar o renovar tu suscripción. Simplemente debes ingresar el Nombre del cupón a la hora de realizar el pago.",
]

export const discountsInfoInviteFriends = [
  "Para invitar amigos, solo debés enviarles el nombre del cupón que sea de tipo: Invitación Amigos, luego si un amigo lo utiliza, tendrá un descuento del 15% y te servirá a vos para tu futura renovación. "
]

export const discountsInviteFriendsText = [
  `Puedes invitar a tus amig@s a que se unan a La Flota obsequiándoles un cupón del 15% de descuento!`, <br />,
  "Por cada persona que compre una Cuenta en La Flota usando tu cupón te regalamos a vos el 15% correspondiente al plan comprado por tu amig@ en crédito de La Flota para tu próxima renovación.",
  "Por ejemplo: si tenés el plan Charly García, si 7 amig@s tuyos se unieran en un año utilizando tu invitación, tu renovación de una Cuenta de Artista sería gratis!", <br />,
  "En tu Panel de Control vas a poder ver quienes de tus amig@s abrieron una Cuenta usando tu cupón, y el crédito que tenés disponible.", <br />,
  `Una vez generado el cupón solo debés envíarselos.`
]

export const discountsCreateCreditByFriendsText = [
  "Como dispones de crédito a favor, por haber invitado a Amig@s que han comprado una suscripción, ",
  "puedes generar un Cupón de Crédito por Amig@s, que te servirá para realizar el pago de la renovación ",
  "de tu cuenta, o para realizar una mejora de Plan.", <br />,
  "Ten en cuenta que el porcentaje de descuento se corresponde al 15% de la suscripción que obtenga tu amig@.", <br />,
  "Si tienes más de 100% de descuento, crearemos un cupón por el 100% y el resto te seguirá quedando ",
  "como crédito a favor."
]

export const discountsCreateCreditWhenOneIsActive = [
  "Vemos que ya tienes un cupon de Crédito por Amig@s y no lo has usado todavía.",
  "Por lo que sumaremos el crédito que tienes disponible a ese cupón.",
  "Recuerda que en caso de superar el 100% de descuento, lo restante te quedará como Crédito."
]

export const discountCreditAtTop = [
  "Ya tienes un cupón de credito sin usar, y con el 100% de descuento.",
  "Primero debés usarlo para poder generar uno nuevo."
]

export const usingDiscountText = discountData => {
  let styleText = { fontSize: "16px", fontWeight: 400 };
  let styleData = { fontSize: "16px", fontWeight: 500 };
  return [
    <span key='title' style={styleText}>Estas usando un Cupón de Descuento</span>, <br key='br-title' />,
    <><span key='owner' style={styleText}>Dueño: </span><b key='b-owner' style={styleData}>{discountData.ownerEmail}</b></>, <br key='br-owner' />,
    discountData.percentageOrAmount === "percentage"
      ? <><span key='discount' style={styleText}>Descuento: </span><b key='b-%' style={styleData}>{discountData.percentage + "%"}</b></>
      : <><span key='amount' style={styleText}>Descuento: </span><b skey='b-discount' tyle={styleData}>{discountData.amount + " " + discountData.currency}</b></>,
  ]
}

export const discountNotUsedText = "El cupón no fue usado por ningun usuario."
export const noMoreExistanceTitle = "El cupón no tiene más existencias";
export const noMoreExistanceSubtitle = ["Encontramos el cupón pero ya no es posible usarlo, al exceder la cantidad de usos posible."];
export const notActiveTitle = "El cupón no está Activo";
export const notActiveSubtitle = ["Encontramos el cupón pero ya no es posible usarlo, el mismo no se encuentra Activo."];
export const incompatibleTitle = "Uso de cupón incompatible";
export const incompatibleSubtitle = ["No puedes usar un cupón de Invitar a Amigos siendo el dueño del mismo."];
export const invititationInRenewTitle = "Uso de cupón incompatible";
export const invititationInRenewSubtitle = ["No puedes usar una invitación de amig@s al renovar tu plan"];
export const notFoundTitle = "La búsqueda no arrojó resultados";
export const notFoundSubitle = ["Asegurate de haber escrito correctamente el nombre del cupón."];
export const errorTitle = "Hubo un error al realizar la búsqueda";
export const errorSubtitle = ["Por favor, intenta nuevamente o contáctate con nosotros."];

//=======================================================SUBSCRIPTIONS==========================================================================\\

export const addPaymentToUserSubtitle = ["Sirve más que nada para pagos que no actualizaron el User por algún error.",
  "Al agregar el ID del Pago, la información del mismo se agregar al Usuario."]

export const inactiveSubscriptionText = "No tienes suscripciones activas. Elige un plan y procede con el pago para que podamos activar tu cuenta.";
export const pendingPaySubscriptionText = "Todavía no realizaste el pago de tu pedido, ni elegiste el medio de pago.";
export const pendingProofSubscriptionText = "Todavía no cargaste el comprobante de tu pago.";
export const warningProofRejectedText = "El comprobante de tu pago fue rechazado, puedes subir uno nuevo o contactarte con nosotros.";
export const pendingProofCheckSubscriptionText = "Todavía no hemos revisado el comprobante de tu pago.";
export const cancelledSubscriptionText = "Tu suscripción se encuentra cancelada.";
export const suspendNoPaySubscriptionText = "Tu suscripción se encuentra suspendida por falta de pago.";
export const suspendInfractionSubscriptionText = "Tu suscripción se encuentra suspendida por infracción de Copyright.";
export const freezedSubscriptionText = "Tu suscripción no te permite realizar nuevos lanzamientos. Debes obtener un nuevo plan.";
export const needRenovationSubscriptionText = "Necesitas realizar la renovación de tu Suscripción. Tienes hasta 5 días después de la fecha.";
export const needVerificationEmailText = "Necesitas verificar tu e-mail antes de elegir tu Plan. Revisa tu correo, si no encuentras un email con el link para verificar tu e-mail, contáctanos."
export const wantToRefundTitle = "¿Seguro que quieres pedir el reembolso?";
export const wantToRefundSubtitle = ["Recuerda que tienes hasta 5 días para pedirlo, una vez realizado el pago.",
  "El reembolso no es inmediato, nos contactaremos contigo para que nos indiques a qué cuenta te enviamos el dinero."];
export const needMigrationText = "Estamos cambiando nuestro proceso de pago, por lo que si ya realizaste el pago y envíaste el comprobante, actualizaremos tu suscripción en breve."
//=======================================================LOGIN-SIGNUP-RESET=====================================================================\\

export const passwordLossText = ["¿Perdiste tu contraseña?", <br />,
  "Por favor, introduce tu correo electrónico.", <br />,
  "Recibirás un enlace para crear una contraseña nueva."]

export const needOtpCodeText = ["Ingresa el código de 6 dígitos que te hemos enviado previamente."]
export const insertNewPasswordText = ["Ingresa la nueva contraseña."]
export const setNewPasswordText = ["Por favor, ingresa una nueva contraseña. ", <br />, "Debe tener al menos 8 carácteres."]
export const errorResetPasswordTitle = "Hubo un error al buscar tu solicitud";
export const errorResetPasswordSubtitle = ["Por favor, intenta nuevamente, o envía un mail a soporte."];
export const noResetPasswordDocTitle = "Solicitud no encontrada";
export const noResetPasswordDocSubtitle = [
  "No encontramos la solicitud de restablecimiento de contraseña.",
  "Por favor, inicia el proceso nuevamente, o envía un mail a soporte."
];
export const expiredResetPassTitle = "Solicitud expirada";
export const expiredResetPassSubtitle = ["Tu solicitud ya expiró.",
  "Por motivos de seguridad, los pedidos tienen un tiempo limitado.",
  "Por favor, realiza el proceso nuevamente."];
export const emailSendedSubtitle = ["Te hemos enviado un mail para que puedas resetear la contraseña."];
export const emailResendedSubtitle = ["Te hemos reenviado el mismo mail para que puedas resetear la contraseña."];
export const otpNotEqual = ["El código OTP ingresado no coincide con aquel que te hemos enviado. ",
  "Si crees que es un error, por favor, envía un mail a soporte."];

export const errorVerificationMailTitle = "Hubo un error al verificar el mail";
export const errorVerificationMailSubtitle = ["Por favor, intenta nuevamente, refrescando esta página o haciendo click ",
  "nuevamente en el botón de Verificar Correo que te hemos enviado."
];

//========================================RE-AUTH=====================================================================\\

export const errorUpdatingEmailTitle = "Hubo un error al actualizar el Email";
export const errorUpdatingEmailSubtitle = ["Por favor intenta nuevamente"];
export const errorUpdatingPasswordTitle = "Hubo un error al actualizar la contraseña";
export const errorUpdatingPasswordSubtitle = ["Por favor intenta nuevamente"];
export const errorPasswordsNotEqualTitle = "Contraseñas no coinciden";
export const errorPasswordsNotEqualSubtitle = ["Las contraseñas no coinciden, verifica que las ingresaste correctamente.",
  "Si no quieres cambiar la contraseña dejala en blanco."];

//===================================================PAYMENTS==========================================================\\

export const userNotFoundText = ["El email ingresado no coincide con ningún usuario."];
export const discountNotFoundText = cuponName => [`No existe un Cupón con el nombre ingresado: ${cuponName}`];

//=================================================OAC FORM==============================================================\\

const styleForLinks = { textDecoration: 'none', color: '#007bff', '&:hover': { textDecoration: 'underline' } };
export const oacSuccessDialogTitle = "OAC solicitado con exito";
export const oacSuccessDialogSubtitle = [<Typography paddingTop={2} sx={{ fontSize: "14px", textAlign: "left" }}>
  Una vez enviada la solicitud, puede demorar algunas semanas en ser verificado. Luego tu material irá directamente allí.
  Puedes leer más al respecto en {' '}
  <Link target="_blank" href="https://artists.youtube.com" sx={styleForLinks}>YouTube for Artists</Link></Typography>, <br />,
  "Recibirás un mail de confirmación de nuestro equipo una vez que hayamos verificado que la solicitud cumple con los requisitos de YouTube."];

export const oacNoAlbumsAvailableText = "El artista que has seleccionado no tiene lanzamientos Online en La Flota.";
export const oacNoAlbumsAvailableTitle = "No tienes lanzamientos Online";
export const oacAdminAcceptText = ["Al aceptar el OAC se debe agregar el OAC al Artista en FUGA.",
  "Además se enviará un mail confirmando el pedido al usuario."]
export const oacAdminRejectText = ["Al rechazar el OAC el estado del artista pasará a OAC Rechazado hasta que el usuario o nosotros editemos el OAC.",
  "Además se enviará un mail avisando al usuario que el pedido fue rechazado con las razones comunes del por qué."]