import React, { useEffect, useState } from "react";
import { Grid, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentsPropsForDataTable, paymentMethodNameToId, paymentStatusNameToStatusId, sortPaymentsByField } from "utils/payments.utils";
import { toWithOutError } from 'utils';
import { getSearchedUserRedux } from "../../redux/actions/UsersActions";
import CustomizedTable from 'components/Table/CustomizedTable';
import { cohesivePurple, whiteColor } from "variables/colors";
import AccountingBar from "components/Navbars/AccountingBar";
import { getQueryPayments } from "utils/payments.utils";
import { userIsAdmin } from "utils/users.utils";
import PaymentActionsDialog from "./PaymentActionsDialog";
import { getPaymentsByFieldRedux, getPaymentsByOuterIdRedux } from "redux/actions/PaymentsActions";
import { paymentsFilterMethodsValues, paymentsFilterStatusValues } from "variables/financial";
import PaymentCreateDialog from "./PaymentCreateDialog";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const PaymentsTable = (props) => {

  const { setOpenErrorSearch, setOpenEmptySearch } = props;

  const dispatch = useDispatch();
  const currentUserData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(currentUserData.rol);
  const payments = useSelector(store => store.payments.payments);
  const rol = currentUserData.rol;

  // COSAS DEL BUSCADOR
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [ourIdSearchValue, setOurIdSearchValue] = useState("");
  const [outerIdSearchValue, setOuterIdSearchValue] = useState("");
  const [paymentsFiltered, setPaymentsFiltered] = useState(payments);
  const [statusFilterValue, setStatusFilterValue] = useState({ id: 'all', name: "Todos los pagos" });
  const [methodFilterValue, setMethodFilterValue] = useState({ id: 'all', name: "Todos los métodos" });

  const defaultFilterParams = {
    field: "status", values: [],
    groupBy: { id: 'all', name: "Todos los pagos" }, orderBy: { field: 'lastPaymentDate', order: 'desc' }
  }

  const [openPaymentActionsDialog, setOpenPaymentActionsDialog] = useState({ open: false, paymentId: "" });
  const [paymentsTableIsOpen, setPaymentsTableIsOpen] = useState(true);
  const [pagePayments, setPagePayments] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchPaymentsParams, setSearchPaymentsParams] = useState({ field: 'none', values: "" });
  const [filterPaymentsParams, setFilterPaymentsParams] = useState(defaultFilterParams);
  const [openCreatePayment, setOpenCreatePayment] = useState(false);

  useEffect(() => {
    let groupBy = filterPaymentsParams.groupBy;
    let paymentsQueried = getQueryPayments(payments, searchPaymentsParams,
      { field: filterPaymentsParams.field, value: groupBy.id });
    setPaymentsFiltered(paymentsQueried);
    setPaymentsLoading(false);
  }, [payments, filterPaymentsParams.orderBy])


  let sortedPayments = sortPaymentsByField(paymentsFiltered, "lastPaymentDate");

  const paymentsTableElements = getPaymentsPropsForDataTable(sortedPayments, setOpenPaymentActionsDialog) || [];
  const paymentsTableHeaders = userIsAdmin(rol) ? ["Acciones", "Email", "Plan", "Estado", "ID Externo", "Nuestro ID", "Fecha Creación", "Pago", "Método", "Cupón", "Monto Bruto", "Monto Neto", "Moneda"] : ["Estado", "Plan", "Fecha Creación", "Método", "Cupón", "Monto Neto", "Moneda"];
  const paymentsTableWidth = userIsAdmin(rol) ? ["5%", "15%", "4%", "10%", "8%", "7", "18%", "18%", "10%", "5%", "5%", "5%", "5%"] : ["15%", "10%", "15%", "15%", "15%", "15%", "15%"];

  const cleanSearchResults = () => {
    setFilterPaymentsParams(defaultFilterParams);
    setSearchPaymentsParams({ field: 'none', values: '' });
    setEmailSearchValue("");
    setOurIdSearchValue("");
    setOuterIdSearchValue("");
    setStatusFilterValue({ id: 'all', name: "Todos los pagos" });
    setMethodFilterValue({ id: 'all', name: "Todos los métodos" });
  }

  const onSearchEmailHandler = async email => {
    setPaymentsLoading(true);

    let userFinded = payments.find(userFromStore => userFromStore.email === email);
    if (!userFinded) {
      setSearchPaymentsParams({ field: 'email', values: email });
      userFinded = await toWithOutError(dispatch(getSearchedUserRedux(email)));
    }

    if (userFinded === "ERROR") { setPaymentsLoading(false); setOpenErrorSearch(true); cleanSearchResults(); return "ERROR"; }
    if (userFinded === "EMPTY") { setPaymentsLoading(false); setOpenEmptySearch(true); cleanSearchResults(); return "EMPTY"; }

    setSearchPaymentsParams({ field: 'ownerId', values: userFinded.id });
    let paymentsFinded = await toWithOutError(dispatch(getPaymentsByFieldRedux('ownerId', userFinded.id, 10)));
    if (paymentsFinded === "ERROR") { setPaymentsLoading(false); cleanSearchResults(); setOpenErrorSearch(true); return "ERROR"; }
    if (paymentsFinded === "EMPTY") { setPaymentsLoading(false); cleanSearchResults(); setOpenEmptySearch(true); return "EMPTY"; }

    setPagePayments(0);
    setPaymentsLoading(false);
  }

  const onSearchPaymentIdHandler = async paymentId => {
    setPaymentsLoading(true);
    setSearchPaymentsParams({ field: 'id', values: paymentId });
    let paymentsFinded = await toWithOutError(dispatch(getPaymentsByFieldRedux('id', paymentId, 1)));
    if (paymentsFinded === "ERROR") { setPaymentsLoading(false); cleanSearchResults(); setOpenErrorSearch(true); return "ERROR"; }
    if (paymentsFinded === "EMPTY") { setPaymentsLoading(false); cleanSearchResults(); setOpenEmptySearch(true); return "EMPTY"; }
    setPagePayments(0);
    setPaymentsLoading(false);
  }

  const onSearchPaymentOuterIdHandler = async outerPaymentId => {
    setPaymentsLoading(true);
    setSearchPaymentsParams({ field: 'outerPaymentId', values: outerPaymentId });
    let paymentsFinded = await toWithOutError(dispatch(getPaymentsByOuterIdRedux(outerPaymentId)));
    if (paymentsFinded === "ERROR") { setPaymentsLoading(false); cleanSearchResults(); setOpenErrorSearch(true); return "ERROR"; }
    if (paymentsFinded === "EMPTY") { setPaymentsLoading(false); cleanSearchResults(); setOpenEmptySearch(true); return "EMPTY"; }
    setPagePayments(0);
    setPaymentsLoading(false);
  }


  const handleEnterKeyPress = (event, searchProps) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Email") onSearchEmailHandler(searchProps.value.trim().toLowerCase());
      if (searchProps.name === "Nuestro ID") onSearchPaymentIdHandler(searchProps.value.trim().toUpperCase());
      if (searchProps.name === "ID Externo") onSearchPaymentOuterIdHandler(searchProps.value.trim());
    }
  }

  const emailSearchProps = {
    name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler,
    value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue
  };

  const ourIdSearchProps = {
    name: "Nuestro ID", handleEnterKeyPress, onSearchHandler: onSearchPaymentIdHandler,
    value: ourIdSearchValue.trim().toUpperCase(), setValue: setOurIdSearchValue
  };

  const outerIdSearchProps = {
    name: "ID Externo", handleEnterKeyPress, onSearchHandler: onSearchPaymentOuterIdHandler,
    value: outerIdSearchValue.trim().toUpperCase(), setValue: setOuterIdSearchValue
  };


  const allSearchers = [emailSearchProps, ourIdSearchProps, outerIdSearchProps];
  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };

  const handleChangePage = (event, newPage) => {
    setPagePayments(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  let usersTableParams = {
    rows: paymentsTableElements, headers: paymentsTableHeaders, columnsWidth: paymentsTableWidth, needPaginationInTable: true,
    totalCount: paymentsFiltered.length, handleChangePage, page: pagePayments, headerColor: cohesivePurple, handleChangeRowsPerPage, rowsPerPage,
    headersHeight: 65, maxLengthChars: 25, maxWidthText: 300, rowsAlignHeaders: 'center', rowsAlignCells: 'center', rowsHeight: 30,
    loading: paymentsLoading,
  };

  const paymentsGroupByStatus = ["Todos los pagos", ...paymentsFilterStatusValues];
  const paymentsGroupByMethod = ["Todos los métodos", ...paymentsFilterMethodsValues];

  const handleChangeGroupByState = async (groupByName, field) => {
    cleanSearchResults();
    setPaymentsLoading(true);
    const statusId = paymentStatusNameToStatusId(groupByName);
    setStatusFilterValue({ id: statusId, name: groupByName });
    setFilterPaymentsParams({
      ...filterPaymentsParams, field,
      groupBy: { id: statusId, name: groupByName || "Todos los pagos" }
    });
    let fieldSelected = statusId !== 'all' ? 'status' : "";
    let values = statusId !== 'all' ? statusId : "";

    let paymentsFinded = await toWithOutError(dispatch(getPaymentsByFieldRedux(fieldSelected, values, rowsPerPage)));
    if (paymentsFinded === "ERROR") { setPaymentsLoading(false); setOpenErrorSearch(true); cleanSearchResults(); return "ERROR"; }
    if (paymentsFinded === "EMPTY") { setPaymentsLoading(false); setOpenEmptySearch(true); cleanSearchResults(); return "EMPTY"; }
  }

  const handleChangeGroupByPaymentMethod = async (groupByName, field) => {
    cleanSearchResults();
    setPaymentsLoading(true);
    const methodId = paymentMethodNameToId(groupByName);
    setMethodFilterValue({ id: methodId, name: groupByName });
    setFilterPaymentsParams({
      ...filterPaymentsParams, field,
      groupBy: { id: methodId, name: groupByName || "Todos los pagos" }
    });
    let fieldSelected = methodId !== 'all' ? 'payment_method' : "";
    let values = methodId !== 'all' ? methodId : "";

    let paymentsFinded = await toWithOutError(dispatch(getPaymentsByFieldRedux(fieldSelected, values, rowsPerPage * 2)));
    if (paymentsFinded === "ERROR") { setPaymentsLoading(false); setOpenErrorSearch(true); cleanSearchResults(); return "ERROR"; }
    if (paymentsFinded === "EMPTY") { setPaymentsLoading(false); setOpenEmptySearch(true); cleanSearchResults(); return "EMPTY"; }
  }

  const groupByStatus = { helper: "Agrupar según estado", field: 'status', values: paymentsGroupByStatus, handleChangeGroupBy: handleChangeGroupByState, value: statusFilterValue }
  const groupByMethod = { helper: "Agrupar según método", field: 'payment_method', values: paymentsGroupByMethod, handleChangeGroupBy: handleChangeGroupByPaymentMethod, value: methodFilterValue }
  const groupByArray = [groupByStatus, groupByMethod];

  let addUserButtonSx = { backgroundColor: cohesivePurple, "&:hover": { backgroundColor: cohesivePurple } };

  return isAdmin
    ? (
      <Grid item xs={12} sx={{ textAlign: "center" }}>

        {openPaymentActionsDialog.paymentId &&
          <PaymentActionsDialog isOpen={openPaymentActionsDialog.open} handleClose={() => setOpenPaymentActionsDialog({ open: false, paymentId: "" })}
            paymentId={openPaymentActionsDialog.paymentId} showOrLoad={'show'} />}

        {openCreatePayment &&
          <PaymentCreateDialog isOpen={openCreatePayment} handleCloseDialog={() => setOpenCreatePayment(false)} />}

        {isAdmin && <Grid item xs={12} paddingBottom={2}>
          <Button variant="contained" sx={addUserButtonSx} onClick={() => setOpenCreatePayment(true)} endIcon={<AttachMoneyIcon />}>
            Crear Pago
          </Button>
        </Grid>}

        <Grid item xs={12} padding={0} >

          <AccountingBar searchArrayProps={allSearchers} cleanSearchResults={cleanSearchResults}
            appBarSx={appBarSx} appBarTitle='Pagos' mainSearchColor={cohesivePurple} isOpen={paymentsTableIsOpen}
            handleCollapseTable={() => setPaymentsTableIsOpen(!paymentsTableIsOpen)} groupByArray={groupByArray} />
        </Grid>

        {paymentsTableIsOpen && <Grid item xs={12} sx={{ margin: 'auto' }}>
          <CustomizedTable {...usersTableParams} />
        </Grid>}
      </Grid>
    ) : null;
}

export default PaymentsTable;