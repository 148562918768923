import { chunkSize, createUpload, fileStateAddOneChunk, startUploadState } from 'factory/uploads.factory';
import * as ReducerTypes from 'redux/actions/Types';
import * as BackendCommunication from 'services/BackendCommunication.js';
import { createTrackNewFileModel } from 'services/CreateModels';
import { writeCloudLog } from 'services/LoggingService';
import { deleteWeirdCharacters } from 'utils/collaborators';
import { SUCCESSFULLY_UPLOADED, START_UPLOAD } from 'variables/uploads.variables';
import { ERROR_UPLOADING } from 'variables/uploads.variables';

export const uploadsStartOneStore = fileIdToBegin => {
  return {
    type: ReducerTypes.UPLOADS_EDIT,
    payload: { fileId: fileIdToBegin, status: START_UPLOAD }
  }
}

export const uplaodsAddSkeletonStore = (fileId, file, existedInFuga) => {
  const skeletonUpload = createUpload(fileId, file, existedInFuga);
  return {
    type: ReducerTypes.UPLOADS_ADD,
    payload: skeletonUpload
  }
}

export const uploadSetFileObject = (uploadStatus, fileObject) => {
  return {
    type: ReducerTypes.UPLOADS_EDIT,
    payload: { fileId: uploadStatus.fileId, fileToUpload: fileObject, fileSize: fileObject.size }
  }
}

export const uploadsStartOneRedux = (fileId, fileObject, userEmail, isFromAlbumInfo) => async dispatch => {
  if (!fileObject) return "NO_FILE";

  let fileData = { size: fileObject.size, name: deleteWeirdCharacters(fileObject.name) };
  isFromAlbumInfo && writeCloudLog(`Loading file from album total info, user: ${userEmail}`, { fileId, fileData }, { notError: "not error" }, "info");
  !isFromAlbumInfo && writeCloudLog(`Loading file from new album flow, user: ${userEmail}`, { fileId, fileData }, { notError: "not error" }, "info");

  const fugaUuid = await BackendCommunication.getTrackUploadUuidFuga(fileId, userEmail, dispatch)
  if (fugaUuid === "ERROR") {
    dispatch({ type: ReducerTypes.UPLOADS_EDIT, payload: { fileId, status: ERROR_UPLOADING } });
    return "ERROR";
  }
  let fileName = fugaUuid + '-' + fileData.name;

  const dataResponse = await BackendCommunication.getUploadFileContext(fileName, fileObject.size, userEmail, dispatch);
  if (dataResponse === "ERROR") {
    dispatch({ type: ReducerTypes.UPLOADS_EDIT, payload: { fileId, status: ERROR_UPLOADING } });
    return "ERROR";
  }

  const uploadedBytes = dataResponse.uploaded;
  const bytesRemaining = fileObject.size - uploadedBytes;
  const endingChunk = Math.min(uploadedBytes + chunkSize, fileObject.size);

  let newFileState = startUploadState(fileName, fileId, fugaUuid, fileObject, bytesRemaining, uploadedBytes, endingChunk)
  dispatch({
    type: ReducerTypes.UPLOADS_EDIT,
    payload: newFileState
  });

  return newFileState;
}

const delay = ms => new Promise(res => setTimeout(res, ms));

export const uploadsAddChunkRedux = (oldUploadState, chunk, userEmail) => async dispatch => {
  const { fileId, fugaUuid, endChunk, fileSize, totalChunksUploaded, fileName } = oldUploadState;

  let formDataChunk = createTrackNewFileModel(chunk, {
    uniqueFileId: fugaUuid, fileSize, chunkIndex: totalChunksUploaded, totalParts: parseInt(fileSize / chunkSize) + 1,
    fileExtension: 'wav', mimeType: 'audio/wave', chunkSize, fileName
  })

  let success = false;
  for (let retry = 0; (retry < 6 && !success); retry++) {
    formDataChunk.append("retry", retry);
    const uploadOneChunkResponse = await BackendCommunication.uploadOneChunk(fileId, formDataChunk, fugaUuid, 'wav', totalChunksUploaded, userEmail, retry, dispatch)
    if (uploadOneChunkResponse !== "ERROR") success = true;
    else {
      success = false;
      await delay(3000 * (retry + 1));
    }
  }

  if (!success) {
    dispatch({ type: ReducerTypes.UPLOADS_EDIT, payload: { fileId, status: ERROR_UPLOADING } });
    return "ERROR";
  }

  const endingChunk = Math.min(endChunk + chunkSize, fileSize);
  let newFileState = fileStateAddOneChunk(oldUploadState, endingChunk, fileSize);

  dispatch({
    type: ReducerTypes.UPLOADS_EDIT,
    payload: newFileState
  });

  return newFileState;
}

export const finishUploadRedux = (fileId, fileName, fugaUuid, userEmail) => async dispatch => {
  let newStatus = SUCCESSFULLY_UPLOADED;
  let finishResponse = await BackendCommunication.finishUpload(fileId, fileName, fugaUuid, userEmail, dispatch);
  if (finishResponse === "ERROR") newStatus = ERROR_UPLOADING;

  dispatch({
    type: ReducerTypes.UPLOADS_EDIT,
    payload: { fileId, status: newStatus }
  });
  return newStatus === SUCCESSFULLY_UPLOADED ? "SUCCESS" : "ERROR";
}

export const uploadsDeleteById = uploadId => {
  return {
    type: ReducerTypes.UPLOADS_DELETE_BY_ID,
    payload: { fileId: uploadId }
  };
};

export const uploadsSignOut = () => {
  return {
    type: ReducerTypes.UPLOADS_SIGN_OUT
  }
}