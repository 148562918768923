import { getFunctions, httpsCallable } from "firebase/functions";
import { copyFormDataToJSON, to } from 'utils';

const functions = getFunctions();

export const writeCloudLog = async (msg, payloadData, payloadError, typeOfLog) => {
  const jsonPayloadData = copyFormDataToJSON(payloadData);
  const jsonPayloadError = copyFormDataToJSON(payloadError);
  const onCallWriteCloudLog = httpsCallable(functions, 'logs-onCallWriteCloudLog');
  const [errorWritingLog] = await to(onCallWriteCloudLog({ msg, payloadData: jsonPayloadData, payloadError: jsonPayloadError, typeOfLog }));

  if (errorWritingLog) return "ERROR";
  return "SUCCESS";
}