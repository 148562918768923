import React, { useEffect, useState } from "react";

import { Grid, Tooltip, Button, IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, DialogContentText, DialogTitle } from '@mui/material';
import TextFieldWithInfo from 'components/TextField/TextFieldWithInfo';
import BasicCheckbox from 'components/Checkbox/BasicCheckbox';
import { Info, Delete } from '@mui/icons-material';
import { peopleRoles, prodAndEngineerRoles } from "variables/collaborators.variables";
import { getHelperCollaboratorText, invalidCollNames, invalidCollRoles } from "utils/textToShow.utils";
import { cloneDeepLimited } from '../../utils';
import { defaultCollaborators, defaultCollaboratorsNoLyricist, expandCollaboratorsRoles, getPlaceholderForColl, groupCollsWithSameName, invalidCollaboratorsNames, isEngineer, isEspecialColl, isLyricist, isLyricistOrComposer, noRequireCollRoles } from "utils/collaborators";
import InfoActionDialog from "components/Dialogs/InfoActionDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const AddCollaboratorsForm = ({ setTrackData, trackData, validator, disabled, isEditing, setCollsAdded, collsAdded }) => {

  const audioLocale = trackData.audio_locale_name;

  const [isInstrumental, setIsInstrumental] = useState(false);
  const [collNamesInvalid, setCollNamesInvalid] = useState(false);
  const [collRolesInvalid, setCollRolesInvalid] = useState(false);

  useEffect(() => {
    if (disabled) return;
    if (audioLocale === "Instrumental") {
      setIsInstrumental(true);
      deleteLyricistCollaborator();
    }
    else {
      setIsInstrumental(false);
      addLyricistCollaborator();
    }
  }, [trackData.audio_locale_name]);

  const deleteLyricistCollaborator = () => {
    validator.current.fields[`Liricista (uno por campo)`] = true;
    setTrackData({ ...trackData, collaborators: trackData.collaborators.filter(coll => !coll.roles.includes("Liricista")) })
  }

  const addOneCollaboratorSkeleton = () => {
    if (trackData.collaborators.length >= 20) return;
    let collaborator = { name: "", roles: [], instruments: [] };
    setTrackData({ ...trackData, collaborators: [...trackData.collaborators, collaborator] });
  }

  const addLyricistCollaborator = () => {
    if (trackData.collaborators.filter(coll => coll.roles.includes("Liricista")).length > 0) return;
    let newCollaboratorsWithLyricist = cloneDeepLimited(trackData.collaborators);
    newCollaboratorsWithLyricist.splice(1, 0, { name: "", roles: ["Liricista"], instruments: [] });
    setTrackData({ ...trackData, collaborators: newCollaboratorsWithLyricist });
  }

  const allCollsAddedCheck = () => {
    // Si era TRUE => lo voy a pasar a false y poder editar roles.
    if (collsAdded) {
      setCollsAdded(false);
      setTrackData({ ...trackData, collaborators: expandCollaboratorsRoles(trackData.collaborators) });
    }
    else {
      if (invalidCollaboratorsNames(trackData.collaborators)) {
        setCollNamesInvalid(true);
        return;
      }
      if (noRequireCollRoles(trackData.collaborators, audioLocale === "Instrumental")) {
        setCollRolesInvalid(true);
        return;
      }
      setCollsAdded(true);
      setTrackData({ ...trackData, collaborators: groupCollsWithSameName(trackData.collaborators) });
    }
  }

  const deleteCollaborators = () => {
    setTrackData({ ...trackData, collaborators: trackData.collaborators.filter((_, i) => i < (isInstrumental ? 2 : 3)) });
  }

  const putDefaultCollaborators = () => {
    setCollsAdded(false);
    setTrackData({ ...trackData, collaborators: audioLocale === 'Instrumental' ? defaultCollaboratorsNoLyricist : defaultCollaborators });
  }

  const handleOnChangeCheckBox = event => event.target.checked ? addOneCollaboratorSkeleton() : deleteCollaborators();

  const handleDeleteCollaborator = cIndex => setTrackData({ ...trackData, collaborators: trackData.collaborators.filter((_, i) => i !== cIndex) });

  const handleAddNameToCollaborator = (cName, index) => {
    setTrackData({ ...trackData, collaborators: trackData.collaborators.map((coll, i) => i === index ? { ...coll, name: cName } : coll) });
  }

  const handleSelectRole = (newRoles, index) => {
    setTrackData({ ...trackData, collaborators: trackData.collaborators.map((coll, i) => i === index ? { ...coll, roles: newRoles } : coll) });
  }

  const getValidatorProps = indexCollaborator => {
    if (indexCollaborator === 0) return { restrictions: 'required|max:100', message: "Debés indicar el nombre del Compositor", validator };
    if (isLyricist(indexCollaborator, audioLocale, isEditing)) return { restrictions: 'required|max:100', message: "Debés indicar el nombre del Liricista", validator };
    return null;
  }

  const moreCollaboratorsCheck = () => {
    if (isEditing) return true;
    return trackData.collaborators.length > 3;
  }

  return (
    <>

      <InfoActionDialog id='coll-names-invalid' isOpen={collNamesInvalid} handleClose={() => setCollNamesInvalid(false)}
        title={'Nombre de colaboradores invalidos'} contentTexts={invalidCollNames} />

      <InfoActionDialog id='coll-roles-invalid' isOpen={collRolesInvalid} handleClose={() => setCollRolesInvalid(false)}
        title={'Roles de colaboradores requeridos no encontrados'} contentTexts={invalidCollRoles} handleOk={() => setCollRolesInvalid(false)} />

      <Grid item xs={12}>
        <DialogTitle id="collaborators-dialog-title" sx={collaboratorsTitleStyle}>Colaboradores de la Canción</DialogTitle>
        <DialogContentText>Ingresa el <b>nombre y apellido</b> de lxs colaboradorxs.</DialogContentText>
        <DialogContentText>Si tu nombre de artista es tu nombre y apellido igual tienes que agregarte como colaborador.</DialogContentText>
        <DialogContentText> No se aceptan nombres artísticos, nombres de bandas, apodos ni abreviaciones.</DialogContentText>
      </Grid>

      <Grid item xs={12}>
        <Button variant="outlined" onClick={putDefaultCollaborators}>
          Limpiar Selección
        </Button>
      </Grid>

      {trackData.collaborators.map((collaborator, index) =>
        (disabled && collaborator.name === "") ? (<></>) : (

          <Grid container item key={index + "bigGrid-coll"}>

            {/* delete icon */}
            <Grid item sx={gridDeleteStyle} key={index + "switch-primary"}>
              {(!isEspecialColl(index, audioLocale, isEditing) && !disabled) &&
                <IconButton color="inherit" size="large" onClick={(_) => handleDeleteCollaborator(index)}>
                  <Delete fontSize="inherit" />
                </IconButton>}
            </Grid>

            {/* name holder */}
            <Grid item sx={gridNameStyle} key={index + "nameGrid=coll"} textAlign="left">
              <TextFieldWithInfo
                name={getPlaceholderForColl(index, disabled, audioLocale, isEditing)}
                required
                disabled={(disabled || collsAdded)}
                sx={textFiedNameStyle}
                label={getPlaceholderForColl(index, disabled, audioLocale, isEditing)}
                value={collaborator.name}
                onChange={event => handleAddNameToCollaborator(event.target.value, index)}
                helperText={getHelperCollaboratorText(index, audioLocale, isEditing)}
                validatorProps={getValidatorProps(index)}
              />
            </Grid>

            {/* select role drop down */}
            <Grid item sx={gridUriStyle} key={index + "selectRol-coll"}>

              <FormControl sx={textFieldRoleStyle} required >
                <InputLabel id="roles">Elige su o sus roles</InputLabel>
                <Select
                  fullWidth
                  labelId="roles"
                  sx={textFieldRoleStyle}
                  id="roles"
                  multiple
                  value={collaborator.roles}
                  disabled={(disabled || collsAdded) || isLyricistOrComposer(index, audioLocale, isEditing)}
                  onChange={event => handleSelectRole(event.target.value, index)}
                  input={<OutlinedInput id="roles" label="Chip" />}
                  renderValue={selected => (
                    selected.map((value, index) => { return (`${index === 0 ? value : `, ${value}`}`) })
                  )}
                  MenuProps={MenuProps}
                >
                  {(isEngineer(index, audioLocale, isEditing) ? prodAndEngineerRoles : peopleRoles)
                    .map(peopleObject => peopleObject.name).map((rol) => (
                      <MenuItem key={rol} value={rol} >
                        {rol}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

            </Grid>
          </Grid>)
      )}

      {!isEditing && !disabled && !collsAdded && <Grid container item xs={12} sx={{ marginLeft: "6%" }}>

        <Grid item xs={7} textAlign="end">
          <BasicCheckbox
            label={"¿Quieres agregar más colaboradores?"}
            onChecked={handleOnChangeCheckBox}
            checked={moreCollaboratorsCheck()}
            color={"#508062"}
          />
        </Grid>

        <Grid item xs={1} textAlign="start">
          <Tooltip title={"Agrega artistas que hayan colaborado en esta canción."} >
            <IconButton>{<Info />}</IconButton>
          </Tooltip>
        </Grid>

      </Grid>}

      {moreCollaboratorsCheck() && !collsAdded && !disabled &&
        <Grid item xs={12} sx={buttonGridtyle}>
          <Button variant="contained" sx={buttonColorStyle} onClick={addOneCollaboratorSkeleton}>
            Agregar Colaborador
          </Button>
        </Grid>}

      {!isEditing && !disabled && <Grid container item xs={12} sx={{ marginLeft: "6%" }}>

        <Grid item xs={7} textAlign="end">
          <BasicCheckbox
            label={"Todos los colaboradores agregados"}
            onChecked={allCollsAddedCheck}
            checked={collsAdded}
            color={"#508062"}
          />
        </Grid>

        <Grid item xs={1} textAlign="start">
          <Tooltip title={"Cuando hayas finalizado de agregar los colaboradores, pasaremos a los instrumentos para cada colaboradador."} >
            <IconButton>{<Info />}</IconButton>
          </Tooltip>
        </Grid>

      </Grid>}
    </>
  )
}

export default AddCollaboratorsForm;

const collaboratorsTitleStyle = { fontSize: "1.5em", textAlign: "center", paddingTop: "16px", paddingBottom: 0 };
const textFiedNameStyle = { width: "100%" }
const textFieldRoleStyle = { width: "90%" }
const gridDeleteStyle = { width: "10%", marginTop: "1.1%", color: "gray" };
const gridNameStyle = { width: "25%" }
const gridUriStyle = { width: "65%", marginTop: "1.1%" };
const buttonGridtyle = { padding: "16px" }
const buttonColorStyle = { backgroundColor: "#508062", '&:hover': { backgroundColor: "#508062" } };
