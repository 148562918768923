export const peopleRoles = [
  { id: 'ACTOR', name: 'Actor' },
  { id: 'ARRANGER', name: 'Arreglista' },
  { id: 'BAND', name: 'Banda' },
  { id: 'CHOIR', name: 'Coro' },
  { id: 'COMPOSER', name: 'Compositor' },
  { id: 'CONDUCTOR', name: 'Conducción' },
  { id: 'ENSEMBLE', name: 'Conjunto' },
  { id: 'WRITER', name: 'Escritor' },
  { id: 'ENGINEER', name: 'Ingeniero' },
  { id: "RECORDING_ENGINEER", name: "Ingeniero de grabación" },
  { id: 'MASTERING_ENGINEER', name: 'Ingeniero de Masterización' },
  { id: 'LYRICIST', name: 'Liricista' },
  { id: 'MIXER', name: 'Mezclador' },
  { id: 'ORCHESTRA', name: 'Orquestra' },
  { id: 'PRODUCER', name: 'Productor' },
  { id: 'REMIXER', name: 'Remixer' },
  { id: 'SOLOIST', name: 'Solista' }
];


export const prodAndEngineerRoles = [
  { id: 'ENGINEER', name: 'Ingeniero' }, { id: "RECORDING_ENGINEER", name: "Ingeniero de grabación" },
  { id: 'MASTERING_ENGINEER', name: 'Ingeniero de Masterización' }, { id: 'PRODUCER', name: 'Productor' }
]

export const prodAndEngineerNames = [
  "Ingeniero", "Ingeniero de grabación", "Ingeniero de Masterización", "Productor"
]

// ROLES ELIMINADOS
// Por problemas con APPLE y redundantes
// { id: 'CONTRIBUTING_ARTIST', name: 'Artista contribuidor' }
// { id: 'FEATURING', name: 'Featuring' }
// { id: 'PERFORMER', name: 'Performer' }