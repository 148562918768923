import React, { useState, useEffect } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Chip, Button, Box, Typography,
} from "@mui/material";
import { commonInstrumentsSpanish } from "variables/instruments.variables";

const AddInstrumentForm = ({ open, onClose, onSave, collName, assignedInstruments }) => {

  const [selectedInstruments, setSelectedInstruments] = useState([]);

  // Sincroniza el estado local con los instrumentos asignados cuando cambian
  useEffect(() => {
    if (open) setSelectedInstruments(assignedInstruments || []);
  }, [open, assignedInstruments]);

  const handleToggleInstrument = (instrument) => {
    setSelectedInstruments((prev) =>
      prev.includes(instrument)
        ? prev.filter((inst) => inst !== instrument) // Deselecciona si ya está
        : [...prev, instrument] // Selecciona si no está
    );
  };

  const handleSave = () => {
    onSave(collName, selectedInstruments); // Guarda los instrumentos seleccionados
    setSelectedInstruments([]); // Limpia la selección
    onClose(); // Cierra el diálogo
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Seleccionar Instrumentos</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Haz clic en los instrumentos que deseas agregar. Los ya seleccionados
          se mostrarán en verde.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          {commonInstrumentsSpanish.map((instrument) => (
            <Chip
              key={instrument.id}
              label={instrument.name}
              onClick={() => handleToggleInstrument(instrument.name)}
              sx={{
                fontSize: "0.85rem",
                fontWeight: 400,
                backgroundColor: selectedInstruments.includes(instrument.name)
                  ? "#56cc82" // Color para seleccionados
                  : "#e0e0e0", // Color para no seleccionados
                color: selectedInstruments.includes(instrument.name)
                  ? "white"
                  : "black",
                cursor: "pointer",
              }}
              onDelete={() => handleToggleInstrument(instrument.name)}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInstrumentForm;
