import * as ReducerTypes from 'redux/actions/Types';
import * as FirestoreServices from 'services/FirestoreServices.js';
import * as BackendCommunication from 'services/BackendCommunication.js';
import { v4 as uuidv4 } from 'uuid';
import { createEditTrackModel, createTrackAssetModel } from 'services/CreateModels';
import { toWithOutError } from 'utils';
import { getAmountOfIsrcCodesToUseFS } from '../../services/FirestoreServices';
import { writeCloudLog } from '../../services/LoggingService';
import { trackNeedISRC } from 'utils/tracks.utils';
import { uplaodsAddSkeletonStore, uploadsStartOneStore } from './UploadsActions';
import { updateCollaboratorsInEditRedux } from './CollaboratorsActions';

export const createTrackLocalRedux = (dataTrack, userId) => {
  dataTrack.ownerId = userId;
  dataTrack.collaborators.map(coll => coll.name.trim())
  dataTrack.id = dataTrack.id || uuidv4();

  return {
    type: ReducerTypes.ADD_UPLOADING_TRACKS,
    payload: dataTrack
  };
}


export const tracksCleanUploadingTracks = () => {
  return {
    type: ReducerTypes.TRACKS_UPLOADING_CLEAN
  }
}

export const deleteTrackInTracksUploading = trackId => {
  return {
    type: ReducerTypes.TRACK_UPLOADING_DELETE,
    payload: trackId
  }
}

const createTrackAssetInAlbumRedux = (dataTrack, userId, artistInvited, artistRecentlyCreated) => async dispatch => {
  let formDataTrack = createTrackAssetModel(dataTrack, artistInvited, artistRecentlyCreated);
  let trackExistedInFuga = dataTrack.existedInFuga;

  writeCloudLog(`creating track asset (TRACK EXISTS IN FUGA:${trackExistedInFuga}) to send to fuga with album fugaId: ${dataTrack.albumFugaId} and ownerEmail: ${dataTrack.ownerEmail}`
    , formDataTrack, { notError: "not error" }, "info");

  let trackFromFuga = {};
  if (trackExistedInFuga) {
    trackFromFuga = await BackendCommunication.attachTrackToAlbumFuga(dataTrack, dispatch);
    if (trackFromFuga === "ERROR") return "ERROR";
  }
  else {
    trackFromFuga = await BackendCommunication.createTrackAssetFuga(formDataTrack, dataTrack.ownerEmail, dataTrack.albumFugaId, dispatch);
    if (trackFromFuga === "ERROR") return "ERROR";
  }

  dataTrack.whenCreatedTS = new Date().getTime();
  dataTrack.lastUpdateTS = dataTrack.whenCreatedTS;
  if (!trackExistedInFuga) {
    dataTrack.fugaId = trackFromFuga.data.response.fugaTrackCreatedInfo.id;
    dataTrack.isrc = trackFromFuga.data.response.fugaTrackCreatedInfo.isrc;
  }
  let trackSizeInMb = dataTrack.track.size
  let trackFilename = dataTrack.track.name;
  let trackForFS = { ...dataTrack, track: { trackSizeInMb, trackFilename } };
  writeCloudLog(`creating track asset post fuga pre fs, with album fugaId: ${dataTrack.albumFugaId} and ownerEmail: ${dataTrack.ownerEmail}`
    , trackForFS, { notError: "not error" }, "info");

  await FirestoreServices.createElementFS(trackForFS, dataTrack.id, userId, "tracks", "totalTracks", 1, dispatch);

  // NUEVO SISTEMA
  // dispatch(uplaodsAddSkeletonStore(dataTrack.fugaId, dataTrack.track));
  return dataTrack;
}

export const createAllTracksAssetsToAlbumRedux = (tracksData, albumId, albumFugaId, userId, ownerEmail, artistInvited, artistRecentlyCreated) => async dispatch => {
  if (tracksData.length === 0) return "ERROR";

  writeCloudLog(`creating all tracks assets pre model with album fugaId: ${albumFugaId} and ownerEmail: ${ownerEmail}`
    , { tracksArray: tracksData }, { notError: "not error" }, "info");

  let amountOfIsrcsCodesMissing = 0;
  tracksData.forEach(track => { if (trackNeedISRC(track)) amountOfIsrcsCodesMissing++; });
  let isrcsCodes = await toWithOutError(getAmountOfIsrcCodesToUseFS(amountOfIsrcsCodesMissing, dispatch));
  if (isrcsCodes === "EMPTY_ISRCS") return "ERROR";

  let isrcIndex = 0;
  tracksData = tracksData.map(track => {
    if (trackNeedISRC(track)) {
      track.isrc = isrcsCodes[isrcIndex];
      isrcIndex++;
    }
    return track;
  });

  let sortedTracks = tracksData.sort((tA, tB) => {
    if (tA.position < tB.position) return -1;
    else return 1;
  });

  for (const dataTrack of sortedTracks) {
    const results = [];
    dataTrack.albumId = albumId; dataTrack.albumFugaId = albumFugaId; dataTrack.ownerId = userId; dataTrack.ownerEmail = ownerEmail;
    let result = await toWithOutError(dispatch(createTrackAssetInAlbumRedux(dataTrack, userId, artistInvited, artistRecentlyCreated)))
    if (result === "ERROR") return "ERROR";
    results.push(result);
  }

  dispatch({
    type: ReducerTypes.EDIT_TRACK_POST_UPLOAD_IN_DB,
    payload: sortedTracks
  });

  return tracksData;
}

export const newUploadAllTracksFilesToAlbumRedux = (tracksData, albumFugaId, ownerEmail) => dispatch => {
  if (tracksData.length === 0) return "ERROR";

  writeCloudLog(`uploading all tracks files with album fugaId: ${albumFugaId} and ownerEmail: ${ownerEmail}`
    , { tracksToUpload: tracksData }, { notError: "not error" }, "info");

  tracksData.forEach(dataTrack => dispatch(uplaodsAddSkeletonStore(dataTrack.fugaId, dataTrack.track, dataTrack.existedInFuga)));
  let tracksFileIds = tracksData.map(dataTrack => dataTrack.fugaId);
  dispatch(uploadsStartOneStore(tracksFileIds[0]));
}

export const editTrackAssetInAlbumRedux = (dataTrack, originalFugaTrack, userEmail, userId) => async dispatch => {
  // FALTA VER QUE HAGO SI AGREGO UN ARTISTA. TENGO QUE CREARLO.
  console.log("EDITING TRACK", dataTrack);
  console.log("ORIGINAL TRACK", originalFugaTrack);
  let editTrackFormData = createEditTrackModel(dataTrack);
  let updateContributorsResponse = await toWithOutError(
    dispatch(updateCollaboratorsInEditRedux(dataTrack.collaborators, originalFugaTrack.contributors,
      originalFugaTrack.id, userEmail, userId)));

  // return;
  writeCloudLog(`editing track asset in fuga with album fugaId: ${dataTrack.albumFugaId} and ownerEmail: ${dataTrack.ownerEmail}`
    , editTrackFormData, { notError: "not error" }, "info");

  // Solo edito metadata, no CONTRIBUTORS.
  let responseTrackFuga = await toWithOutError(BackendCommunication.editTrackFugaById(editTrackFormData, originalFugaTrack.id, dispatch));
  if (responseTrackFuga === "ERROR") return "ERROR";

  dataTrack.lastUpdateTS = dataTrack.whenCreatedTS;
  await FirestoreServices.updateElementFS(trackForFS, dataTrack.id, userId, "tracks", dispatch);
  return dataTrack;
}