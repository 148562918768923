import { v4 as uuidv4 } from 'uuid';
import { peopleRoles, prodAndEngineerNames } from 'variables/collaborators.variables';
import { commonInstrumentsSpanish } from 'variables/instruments.variables';

export const defaultCollaborators = [
  { name: "", roles: ["Compositor"], instruments: [] }, { name: "", roles: ["Liricista"], instruments: [] }, { name: "", roles: [], instruments: [] }
];

export const defaultCollaboratorsNoLyricist = [
  { name: "", roles: ["Compositor"], instruments: [] }, { name: "", roles: [], instruments: [] }
];

export const deleteWeirdCharacters = text => {
  return text.normalize('NFD')
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize();
}

// Agrupar colaboradores por nombre y combinar roles e instrumentos
export const groupCollsWithSameName = collaborators => {

  const groupedCollaborators = collaborators.reduce((acc, curr) => {
    const existing = acc.find((item) => item.name === curr.name);
    if (existing) {
      // Combinar roles e instrumentos si ya existe un colaborador con el mismo nombre
      existing.roles = Array.from(new Set([...existing.roles, ...curr.roles]));
      existing.instruments = Array.from(new Set([...existing.instruments, ...(curr.instruments || [])]));
    }
    else acc.push({ ...curr });
    return acc;
  }, []);

  return groupedCollaborators;
}
export const getAllPeopleToCreateFromUploadingTracks = uploadedTracks => {
  let people = [];
  uploadedTracks.forEach(track => {
    track.collaborators.forEach(coll => {
      if (!coll.personExistsInFuga && !people.map(p => p.name).includes(coll.name)) people.push({ name: coll.name });
    })
  });
  return people;
}

export const getPeopleToCreateFromEditTrack = colls => {
  let peopleToCreate = [];
  colls.forEach(coll => {
    if (!peopleToCreate.map(p => p.name).includes(coll?.person?.name || "") && coll.person.id === "create-new")
      peopleToCreate.push({ name: coll.person.name });
  })
  return peopleToCreate;
}

const getPersonIdFromPeople = (peopleWithId, personName) => {
  let peopleWiIdNotNull = peopleWithId.filter(person => person !== null && person.id !== null);
  let result = peopleWiIdNotNull.find(person =>
    deleteWeirdCharacters(person.name.toLowerCase()) === deleteWeirdCharacters(personName.toLowerCase()));
  if (result && result.id !== "") return result.id;
}

export const getAllCollaboratorsToAttachFromUploadingTracks = (uploadedTracks, peopleWithId, ownerId, ownerEmail) => {
  let collaboratorsForEachTrack = [];
  uploadedTracks.forEach(track => {
    track.collaborators.forEach(coll => {
      if (coll.name !== "") {
        coll.roles.forEach(collRol => {
          let collToAdd = {
            trackFugaId: track.fugaId, id: uuidv4(), added: false, ownerEmail, ownerId,
            name: coll.name, role: getRoleIdByName(collRol), person: getPersonIdFromPeople(peopleWithId, coll.name)
          }
          // Chequeo que no se agregaron mismos colaboradores con mismo rol a un Track.
          if (!collaboratorsForEachTrack.find(collAdded => (collAdded.trackFugaId === collToAdd.trackFugaId)
            && (collAdded.name === collToAdd.name) && (collAdded.role === collToAdd.role)))
            collaboratorsForEachTrack.push(collToAdd);
        })
      }
    })
  });
  return collaboratorsForEachTrack;
}

export const getAllPerformersToAttachFromUploadingTracks = (uploadedTracks, peopleWithId, ownerId, ownerEmail) => {
  let performersForEachTrack = [];
  uploadedTracks.forEach(track => {
    track.collaborators.forEach(coll => {
      if (coll.name !== "") {
        coll.instruments.forEach(collInstrument => {
          let collToAdd = {
            trackFugaId: track.fugaId, id: uuidv4(), added: false, ownerEmail, ownerId,
            name: coll.name, instrument: getInstrumentIdByName(collInstrument), person_id: getPersonIdFromPeople(peopleWithId, coll.name)
          }
          // Chequeo que no se agregaron mismos colaboradores con mismo rol a un Track.
          if (!performersForEachTrack.find(collAdded => (collAdded.trackFugaId === collToAdd.trackFugaId)
            && (collAdded.name === collToAdd.name) && (collAdded.instrument === collToAdd.instrument)))
            performersForEachTrack.push(collToAdd);
        })
      }
    })
  });
  return performersForEachTrack;
}

export const getCollaboratorsToAddFromEditTrack = (collaborators, peopleWithId, trackFugaId, ownerId, ownerEmail) => {
  let collsToUpdate = [];
  collaborators.forEach(coll => {
    if (coll.id === 'create-new') {
      let newColl = {
        trackFugaId, id: uuidv4(), name: coll.person.name, ownerId, ownerEmail, role: coll.role.id,
        person: coll.person.id === 'create-new' ? getPersonIdFromPeople(peopleWithId, coll.person.name) : coll.person.id
      }
      const isDuplicate = collsToUpdate.some(existingColl =>
        existingColl.name === newColl.name && existingColl.role === newColl.role
      );
      if (!isDuplicate) collsToUpdate.push(newColl);
    }
  })
  return collsToUpdate;
}

export const getAllCollaboratorsBasicInfoFromTracks = uploadedTracks => {
  let collaboratorsForEachTrack = [];
  uploadedTracks.forEach((track, trackIndex) => {
    track.collaborators.forEach(coll => {
      if (coll.name !== "") {
        coll.roles.forEach(collRol => {
          let collToShow = { name: coll.name, role: getRoleIdByName(collRol), key: track.id + coll.name + getRoleIdByName(collRol), trackNumber: trackIndex + 1 };
          if (!collaboratorsForEachTrack.find(collAdded => collToShow.key === collAdded.key))
            collaboratorsForEachTrack.push(collToShow);
        })
      }
    })
  });
  return collaboratorsForEachTrack;
}

export const fromCollaboratorsToContributors = (myCollaborators, fugaCollaborators) => {
  let trimmedCollabs = myCollaborators.map(collab => { return { ...collab, name: collab.name.trim() } });
  let noEmptyCollabs = trimmedCollabs.filter(collab => collab.name !== "");
  return noEmptyCollabs.flatMap(myCollab => {
    return myCollab.roles.map(myRole => {
      // Buscar si ya existe el colaborador en FUGA por nombre
      const existingFugaPerson = fugaCollaborators.find(fugaCollab => fugaCollab.person.name.trim() === myCollab.name);

      if (existingFugaPerson) {
        // Buscar si el rol también ya existe para esa persona en FUGA
        const existingRole = fugaCollaborators.find(
          fugaCollab => fugaCollab.person.name.trim() === myCollab.name && fugaCollab.role.id === getRoleIdByName(myRole)
        );

        if (existingRole) {
          // Si ya existe el colaborador con el mismo rol en FUGA, reutilizamos todo
          return {
            id: existingRole.id,
            person: {
              id: existingRole.person.id,
              name: existingRole.person.name
            },
            role: { id: existingRole.role.id }
          };
        } else {
          // Si la persona existe pero el rol no, se crea un nuevo colaborador con el mismo `person.id` pero con un rol nuevo
          return {
            id: 'create-new', // Generar un nuevo ID para el colaborador
            person: {
              id: existingFugaPerson.person.id,
              name: existingFugaPerson.person.name
            },
            role: { id: getRoleIdByName(myRole) }
          };
        }
      } else {
        // Si el colaborador no existe, se crea uno nuevo
        return {
          id: "create-new", // Generar nuevo ID para el colaborador
          person: {
            id: "create-new", // Generar nuevo ID para la persona
            name: myCollab.name
          },
          role: { id: getRoleIdByName(myRole) }
        };
      }
    });
  });
}

export const detectRemovedCollaborators = (myCollaborators, fugaCollaborators) => {
  const removedCollaborators = fugaCollaborators.filter(fugaCollab => {
    // Verificamos si el colaborador con el rol en FUGA ya no está en la nueva lista
    const stillExists = myCollaborators.some(myCollab => {
      return myCollab.name === fugaCollab.person.name &&
        myCollab.roles.includes(getRoleNameById(fugaCollab.role.id));
    });
    return !stillExists; // Si no existe, significa que fue eliminado
  });

  return removedCollaborators;
};

export const mapFugaCollaboratorsToOurs = fugaColls => {
  if (!fugaColls) return [];
  let collaborators = [];
  fugaColls.forEach(fugaColl => {
    let result = { name: fugaColl.person.name, roles: [getRoleNameById(fugaColl.role.id)] };
    collaborators.push(result);
  })
  return collaborators;
}

export const getRolesNamesById = roles => {
  return roles.map(rolId => peopleRoles.find(rolObject => rolObject.id === rolId)?.name || "")
}

export const getRoleNameById = roleId => {
  return peopleRoles.find(rolObject => rolObject.id === roleId)?.name || "";
}

export const getRoleIdByName = roleName => {
  return peopleRoles.find(rolObject => rolObject.name === roleName)?.id || "";
}

export const getInstrumentIdByName = insName => {
  return commonInstrumentsSpanish.find(insObject => insObject.name === insName)?.id || "";
}

export const invalidCollaboratorsNames = colls => {
  let names = colls.map(coll => coll.name);
  if (names.some(name => name.split(' ').length < 2)) return true;
  return false;
}

export const noRequireCollRoles = (colls, isInstrumental) => {
  let allRoles = colls.flatMap(coll => coll.roles);
  let prodAndEngineerPresent = allRoles.some(role => prodAndEngineerNames.includes(role))
  if (isInstrumental && allRoles.includes("Compositor") && prodAndEngineerPresent) return false;
  if (!isInstrumental && allRoles.includes("Compositor") && allRoles.includes("Liricista") && prodAndEngineerPresent) return false;
  return true;
}

export const isComposer = (index, isEditing) => {
  if (isEditing) return false;
  return index === 0;
}

export const isLyricist = (index, audioLocale, isEditing) => {
  if (isEditing) return false;
  return index === 1 && audioLocale !== "Instrumental";
}

export const isEngineer = (index, audioLocale, isEditing) => {
  if (isEditing) return false;
  return (index === 1 && audioLocale === "Instrumental") ||
    (index === 2 && audioLocale !== "Instrumental");
}

export const isLyricistOrComposer = (index, audioLocale, isEditing) => {
  return isComposer(index, isEditing) || isLyricist(index, audioLocale, isEditing);
}

export const isEspecialColl = (index, audioLocale, isEditing) => {
  if (isEditing) return false;
  if (index === 0) return true; // Compositor
  if (isLyricist(index, audioLocale, isEditing)) return true; // Lyricist
  else if (isEngineer(index, audioLocale, isEditing)) return true; // Ingeniero
  return false;
}

export const getPlaceholderForColl = (index, disabled, audioLocale, isEditing) => {
  let indexToRest = audioLocale === 'Instrumental' ? 1 : 2
  if (disabled || !isEspecialColl(index, audioLocale, isEditing)) return `Colaborador/a/x ${index - indexToRest > 0 ? index - indexToRest : 1}`;
  else {
    if (index === 0) return "Compositor/a/x (uno por campo)";
    if (isLyricist(index, audioLocale, isEditing)) return "Liricista (uno por campo)";
    if (isEngineer(index, audioLocale, isEditing)) return "Productor/Ingeniero"
  }
}

export const hasProdOrEngineerRole = collaborators => {
  const prodAndEngineerRoleNames = prodAndEngineerRoles.map((role) => role.name);
  return collaborators.some((collaborator) =>
    collaborator.roles.some((role) => prodAndEngineerRoleNames.includes(role))
  );
};

const hasLyricistRole = collaborators => {
  return collaborators.some((collaborator) =>
    collaborator.roles.includes("Liricista")
  );
};

export const hasInstrumentAssigned = collaborators => {
  return collaborators.some(collaborator => collaborator.instruments && collaborator.instruments.length > 0);
};

export const expandCollaboratorsRoles = collaborators => {
  return collaborators.flatMap(collaborator =>
    collaborator.roles.map((role, i) => ({
      name: collaborator.name,
      roles: [role],
      instruments: i === 0 ? collaborator.instruments : []
    }))
  );
};
